import { observable } from 'mobx';
import * as LocalStore from './LocalStore';
import * as SessionStore from './SessionStore';

const deviceInfo = observable({
    dataset : {
        ctn:"",
    },
    setData(data) {
        this.dataset = data;
    },
    getData() {
        return this.dataset;
    },

    /* example */
    setCtn(data) {
        this.setList({
            ...this.dataset,
            ctn : data
        })
    },
    getCtn() {
        return this.dataset.ctn;
    }
});

export default deviceInfo;