import React, { useState, useEffect, useCallback } from 'react';
import { observer } from 'mobx-react';
import $ from 'jquery'
import store from '../../store';
import { isDataCheck, isNullData } from '../../common/common';
import { layerPopup, toast } from '../../libs/common';
import SohoApi from '../../api/SohoApi';
import * as CONSTS from '../../const/index'

const Basket = observer(({ location, history, match }) => {

    const { userData } = store;
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalCnt, setTotalCnt] = useState(0);
    const [orderList, setOrderList] = useState({});

    let products = userData.dataset.basketList;

    useEffect(() => {
        $('body').addClass('bg_gray');
        $('html').removeClass('noscroll');

        return () => {
            $('body').removeClass('bg_gray');
        }
    }, [])

    useEffect(() => {
        // console.log("basketList", userData.dataset.basketList);
        const CountForPills = userData.dataset.basketList.map((item) => { return item.prdtCnt })
        const PriceForPills = userData.dataset.basketList.map((item) => { return item.price * item.prdtCnt })
        setTotalPrice(PriceForPills.reduce((a, b) => a + b, 0));
        setTotalCnt(CountForPills.reduce((a, b) => a + b, 0));
    }, [userData.dataset.basketList])
    
    const IncreaseClick = (prdtId, options) => {
        const sellectOptionDesc = options?.map((item) => (item.optionDesc)).join();
        const selectPrice = products.map((product) => {
            if(prdtId === product.prdtId && sellectOptionDesc === product.options?.map((optitem) => optitem.optionDesc).join() && product.prdtCnt < 10) {
                return { ...product, prdtCnt: product.prdtCnt + 1, totalPrice: product.price * (product.prdtCnt+1)  }
            } else {
                return product;
            }
        });
        userData.setBasketPut(selectPrice);
    };

    const DecreaseClick = (prdtId, options) => {
        const sellectOptionDesc = options?.map((item) => (item.optionDesc)).join();
        const selectPrice = products.map((product) => {
            if(prdtId === product.prdtId && sellectOptionDesc === product.options?.map((optitem) => optitem.optionDesc).join() && product.prdtCnt > 1) {
                return { ...product, prdtCnt: product.prdtCnt - 1, totalPrice: product.price * (product.prdtCnt-1) }
            } else {
                return product;
            }
        });
        userData.setBasketPut(selectPrice);
    };

    const BasketDelClick = useCallback((prdtId, options) => {
        const sellectIdOptionDesc = prdtId + options.map((item) => (item.optionDesc)).join();
        
        const deleteProduct = userData.dataset.basketList.filter((item) => {
            if(sellectIdOptionDesc !== item.prdtId + item.options.map((optitem) => optitem.optionDesc).join()) {
                return item;
            }
        });
        userData.setBasketPut(deleteProduct);
        toast('선택하신 메뉴가 삭제되었습니다.');
    }, []);

    const goMenuClick = () => {
        history.push(`/menu/${match.params.storeloc}/${match.params.storeId}?tblNum=${userData.dataset.tblNum}`);
    };

    const OrderCompleteHandler = async() => {
        try {
            SohoApi.getStoreOrderInfo({
                storeId: match.params.storeId,
                tblNum: userData.dataset.tblNum,
                totalPrice: `${products.map((item) => { return item.price * item.prdtCnt }).reduce((a, b) => a + b, 0)}`,
                productList : products.map((item, index) => {
                    if(!isDataCheck(item.options)) {
                        return { prdtId: item.prdtId, prdtNm: item.prdtNm, price: `${item.price}`, prdtCnt : item.prdtCnt, options: {} }
                    } else {
                        return { prdtId: item.prdtId, prdtNm: item.prdtNm, price: `${item.price}`, prdtCnt : item.prdtCnt,
                            options: {
                                options: item.options.map((optitem, index) => {
                                    return {
                                        id: optitem.id,
                                        name: optitem.name,
                                        optionDesc: optitem.optionDesc,
                                        optionName: optitem.optionName,
                                        price: optitem.price
                                    }
                                })
                            }
                        }
                    }
                })
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setOrderList(rst.data);
                    layerPopup.show('#basket_table_popup');
                }
            }).catch((e) => {
                console.log(e);
                toast('주문 처리에 실패했습니다. 다시 시도해 주세요');
            })
        } catch (e) {
            console.log(e);
            toast('주문 처리에 실패했습니다. 다시 시도해 주세요');
        }
    };

    const closePopup = () => {
        layerPopup.hide("#basket_table_popup", () => {
            userData.setBasketPut([]);
            history.replace(`/live/${match.params.storeloc}/${match.params.storeId}?tblNum=${userData.dataset.tblNum}`);
        });
    }

    return (
        <>
            <div className="content basket">
                <div className="page_title_wrap">
                    <div className="inner">
                        <button type="button" className="btn_back" onClick={()=>history.goBack()}><span className="blind">뒤로가기</span></button>
                        <h2>장바구니</h2>
                    </div>
                </div>
                { isDataCheck(products) ?
                    <>
                        <ul className='basket_list'>
                            { products.map((item, index) => (
                                <li index={item.prdtId}>
                                    { isDataCheck(item.prdtImg) ?
                                        <span className="food_thumb" style={{backgroundImage: `url(${item.prdtImg})`}}></span>
                                    :
                                        <span className="food_thumb"></span>
                                    }
                                    <span className="food_name">{item.prdtNm}</span>
                                    { item.options.length > 0 ?
                                        <>
                                            <span className="food_opt">
                                                {`${item.options.map((item) => item.optionDesc).join(' / ')}`}
                                            </span>
                                        </>
                                    :
                                        <></>
                                    }
                                    <span className="food_price"><em>{parseInt(item.price * item.prdtCnt).toLocaleString()}</em><span>원</span></span>
                                    <button type="button" className="btn_list_del" onClick={() => BasketDelClick(item.prdtId, item.options)}>
                                        <span className="blind">리스트 삭제</span>
                                    </button>
                                    <div className="quantity_box">
                                            <button type="button" className="btn_decrease" onClick={()=> DecreaseClick(item.prdtId, item.options)}>
                                                <span className="blind">감소</span>
                                            </button>

                                        <span className="i_num" id={item.prdtId}>{item.prdtCnt}</span>
                                        { item.prdtCnt < 10 &&
                                            <button type="button" className="btn_increase" onClick={()=> IncreaseClick(item.prdtId, item.options)}>
                                                <span className="blind">증가</span>
                                            </button>
                                        }
                                    </div>
                                </li>
                            ))
                            }
                        </ul>
                        <button type="button" className="btn_add_menu" onClick={()=> goMenuClick()}><span className="btn_txt"><i className="ico_add"></i>메뉴 추가하기</span></button>
                    </>
                :
                    <div className="page_empty">
                        <div className="empty_inner">
                            <div className="middle_inner">
                                <span className="ic_basket"></span>
                                <p>장바구니가 비어있습니다.</p>
                                <button type="button" className="btn_add_menu2 pt_color" onClick={()=> goMenuClick()}>메뉴 추가하기</button>
                            </div>
                        </div>
                    </div>
                }
            </div>

            { products.length > 0 &&
                <div className="sum_fixed">
                    <dl className="sum_total">
                        <dt>총 주문금액</dt>
                        <dd><em>{parseInt(totalPrice).toLocaleString()}</em>원</dd>
                    </dl>
                    <button type="button" className="btn_active btn_point" onClick={() => OrderCompleteHandler()}><span className="total_num" >{totalCnt}</span>주문 완료하기</button>
                </div>
            }
            <div className="modal fadeIn" id="basket_table_popup">
                <div className="dim"></div>
                <div className="modal_inner">
                    <div className="modal_content modal_receipt">
                        <div className="receipt_bg receipt_top">
                            <span className="ic_chk"></span>
                            <span className="i_title">주문완료</span>
                            <span className="i_greeting">맛있게 조리 중입니다. 조금만 기다려주세요!</span>
                        </div>
                        <span className="dotted_line"><span className="blind">절취선</span></span>
                        <div className="receipt_bg receipt_cont">
                            <div className="receipt_sum">
                                <span className="sum_title">총 주문 금액</span>
                                <span className="total_price"><em>{parseInt(orderList?.totalPrice).toLocaleString()}</em><span>원</span></span>
                            </div>
                            <div className="order_detail">
                                { !isNullData(orderList) ?
                                    <ul>
                                        { orderList.orderProductList?.map((item, index) => (
                                            <li>
                                                <div className="order_main">
                                                    <span className="order_name">{item.prdtNm}x{item.prdtCnt}</span>
                                                    <span className="order_price"><em>{(parseInt(item.price) * item.prdtCnt).toLocaleString()}</em><span>원</span></span>
                                                </div>
                                                { (isDataCheck(item.options)) ?
                                                    item.options.options?.map((optitem, index) => (
                                                        <div className="order_sub">
                                                            <span className="order_name">{`${optitem.optionName === '업그레이드' ? '' : optitem.optionName} ` + optitem.name}</span>
                                                            <span className="order_price"><em>+{parseInt(optitem.price).toLocaleString()}</em><span>원</span></span>
                                                        </div>
                                                    ))
                                                :
                                                    <></>
                                                }
                                            </li>
                                        ))
                                        }
                                    </ul>
                                :
                                    <></>
                                }
                            </div>
                        </div>
                        <button type="button" className="btn_lg2 btn_point" onClick={()=> closePopup()} >확인</button>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Basket;