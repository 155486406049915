import { observable } from 'mobx';
import * as LocalStore from './LocalStore';

const initialDataSet = {
    comId: '', 
    storeId : '', //매장아이디
    paramObj: null,  // ? 이후로 전달된 변수리스트
    basketList : [], //장바구니
    menuTab : 'fullmenu',
    tblNum : '1',
    menuDetailbackFalg : false,
    drgPopupHideFlag : false,
    prdtId : ''
}

const userData = observable({
    dataset : initialDataSet,
    initialize() { 
        this.dataset = initialDataSet;
    },
    setList(data) {
        this.dataset = data;
    },
    getList() {
        return this.dataset;
    },
    
    /** example */
    setComId(id) {
        this.setList({
            ...this.dataset,
            comId : id
        })
    },
    getComId() {
        return this.dataset.comId;
    },
    setParamObj(obj) {
        this.setList({
            ...this.dataset,
            paramObj : obj
        })
    },
    getParamObj() {
        return this.dataset.paramObj;
    },
    setStoreId(data) {
        this.setList({
            ...this.dataset,
            storeId : data
        })
    },
    setBasketPut(data) {
        this.setList({
            ...this.dataset,
            basketList : data
        })
    },
    setMenuTabList(data) {
        this.setList({
            ...this.dataset,
            menuTab : data
        })
    },
    setTableNum(data) {
        this.setList({
            ...this.dataset,
            tblNum : data
        })
    },
    setMenuDetailBackFlag(data) {
        this.setList({
            ...this.dataset,
            menuDetailbackFalg : data
        })
    },
    setDrgPopupHideFlag(data) {
        this.setList({
            ...this.dataset,
            drgPopupHideFlag : data
        })
    },
    setPrdtId(data) {
        this.setList({
            ...this.dataset,
            prdtId : data
        })
    }

});

export default userData;