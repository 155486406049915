import { observer } from 'mobx-react'
import React, {useEffect, useState} from 'react'
import SohoApi from '../../../api/SohoApi';
import $ from 'jquery'
import { isDataCheck, isNullData, menuBasketPush, numberWithCommas, PairingMenuBasketPush } from '../../../common/common';
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
    Pagination
  } from "swiper";
import userData from '../../../store/userData';
import { toast } from '../../../libs/common';
import * as CONSTS from '../../../const/index'

SwiperCore.use([ Pagination ]);

const DetailMenu = observer(({ history,match }) => {
    const [selecList, setSelecList] = useState([]); 
    const [totalPrice, setTotalPrice] = useState(0)
    const [optionList, setOptionList] = useState([]);
    const [count, setCount] = useState(1);
    const [foodDetail2, setFoodDetail2] = useState([]);
    const [fairingMenu, setFairingMenu] = useState([]);

    useEffect(() => { 
        console.log("match",history.location.state);
        const prevApi = async () => {
            await fetchProductDetailList();
            fetchPairingList();
        }
        prevApi()
       
    },[])

    //총 주문금액 실시간
    useEffect(() => {
        totalOrderPrice();
    },[count, selecList, foodDetail2, optionList])

    //처음 화면 들어왔을 때 default 체크
    useEffect(() => {
        if(match.params.storeloc === "in") defaultCheck();
    },[optionList])

     //메뉴상세호출
     const fetchProductDetailList = async() => {
        try {
            await SohoApi.getProductDetailList({
                prdtId: match.params.prdtId //상품 id
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setFoodDetail2(rst?.data);
                    setOptionList(rst?.data?.options?.options);
                }
            }).catch((e) => {
                toast('메뉴 상세 조회에 실패 했습니다.');
                history.goBack();
            })
        } catch(e) {
            toast('메뉴 상세 조회에 실패 했습니다.');
            history.goBack();
        }
    }

    //페어링 리스트
    const fetchPairingList = async() => {
        try {
            await SohoApi.getPairingList({
                prdtId: match.params.prdtId //상품 id
            }).then((rst)=> {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setFairingMenu(rst.data);
                }
            })
        } catch(e) {
            console.log(e)
        }
    }

    //TODO : fetchProductDetailList() 불러올 때 넣어버리기
    const optionFilter = (data) => {
        // const optionTypeO = foodDetail.options.options;
        setOptionList(data);
        // defaultCheck(optionTypeO);
        // return optionTypeO;
    }

    //첫 화면 진입시 default 체크
    const defaultCheck = () => {
        //옵션에서 단일 선택만 불러오기
        try {
            const searchOption = optionList?.map((items) => { 
                if(items.optnGubun === "S"){
                    console.log("items.optionType", items.optionType)
                    return items.optionType;
                }
            })
            //단일선택 옵션이 있을경우에만 로직 진행
            if(searchOption?.length > 0){
                searchOption.forEach((items, idx) => {
                    if(items !== undefined){
    
                        //해당 각 id의 첫번째 항목만 checked = true
                        const firstItem = document.getElementById(items + "0");
                        firstItem.checked = true;
    
                        //selecList에 담기
                        const obj = {
                            "id" : firstItem.getAttribute("menuId"),
                            "optionName" : firstItem.name,
                            "price" : firstItem.getAttribute("price"),
                            "name" :  firstItem.getAttribute("menu"),
                            "optionDesc" : `${firstItem.name === '업그레이드' ? '' : firstItem.name} ` + firstItem.getAttribute("menu") + (firstItem.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                           parseInt(firstItem.getAttribute("price")).toLocaleString() + '원)')
                        }
                        setSelecList(oldArray => [...oldArray, obj ])
                    }
                })
            }
        } catch(e) {
            toast("메뉴 상세 조회에 실패 했습니다.")
            history.goBack();
        }
        
    }

    //장바구니 담기 클릭 했을 때 
    const optionCheck = () => {
        let options = [];
        const radioCheck = document.getElementsByClassName("radio_check");
        const check = document.getElementsByClassName("chk");

        Array.from(radioCheck).forEach((items,idx) =>{
            if(items.checked === true){
               const obj = {
                   "id" : items.getAttribute("menuId"),
                   "optionName" : items.name,
                   "price" : items.getAttribute("price"),
                   "name" : items.getAttribute("menu"),
                   "optionDesc" : `${(items.name === '업그레이드' ? '' : items.name)} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                  parseInt(items.getAttribute("price")).toLocaleString() + '원)')
               } 
               options.push(obj);
            }
        })

        Array.from(check).forEach((items,idx) => {
            if(items.checked === true){
                const obj = {
                    "id" : items.getAttribute("menuId"),
                    "optionName" : items.name,
                    "price" : items.getAttribute("price"),
                    "name" : items.getAttribute("menu"),
                    "optionDesc" : `${items.name === '업그레이드' ? '' : items.name} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                   parseInt(items.getAttribute("price")).toLocaleString() + '원)')
                }
                options.push(obj);
            }
        })
        return options;
    }
    
    //장바구니 담기
    const basketPut = async () => {
        console.log("total",totalPrice);
        const options = await optionCheck();
        console.log("total", totalPrice);

        //store에 넣을 데이터를 가져온다
        const menu = {
            prdtId : foodDetail2.prdtId,
            tag : foodDetail2.tag,
            prdtNm : foodDetail2.prdtNm,
            prdtDesc : foodDetail2.prdtDesc,
            allergy : foodDetail2.allergy,
            ingredients: foodDetail2.ingredients,
            prdtInfo: foodDetail2.prdtInfo,
            eatingTips: foodDetail2.eatingTips,
            prdtImg : !isNullData(foodDetail2.mediaList) ? foodDetail2.mediaList[0].mediaPath : history.location.state,
            // vodPath : foodDetail.vodPath,
            // useType : foodDetail.useType,
            price : totalPrice / count, // 제품가격
            totalPrice : totalPrice, // 총가격
            prdtCnt : count, // 음식갯수
            // options : selecList.length > 0 ? selecList : []
            options : options
        };
        await menuBasketPush(menu, count);
        history.push(`/live/${match.params.storeloc}/${match.params.storeId}?tblNum=${userData.dataset.tblNum}`);
        //공통으로 쓰일지 몰라 따로 뺌
        // menuBasketPush(menu);
    }

     //좋아요
     const fetchLike = async() => {
         if($('.btn_favorite').hasClass('on')) {
            $('.btn_favorite').removeClass('on')
        } else {
                try {
                   const likeApi = await SohoApi.getAddInflowHistory({
                        storeId: match.params.storeId,
                        histType: 'L',
                        flowType: '',
                        flowPath: match.params.prdtId
                    })
                    if(likeApi.retCode === "0000"){
                        toast("좋아요");
                        $('.btn_favorite').toggleClass('on');
                    }
                } catch(e) {
                    console.log("errer",e);
                }
        }
    }

    // 라디오박스 클릭
    const inputClickRadioBox = (e) => {
        //총 주문금액을 책정하기 위해 가격과 메뉴이름을 가져온다
        const obj = {
            "id" : e.currentTarget.getAttribute("menuId"),
            "optionName" : e.currentTarget.name,
            "name" :  e.currentTarget.getAttribute("menu"),
            "price" : e.currentTarget.getAttribute("price"),
            "optionDesc" : (e.currentTarget.name === '사이즈' ? `${e.currentTarget.name} ` : '') + 
                            e.currentTarget.getAttribute("menu") + 
                            (e.currentTarget.getAttribute("price") === "0" ? "(추가금액 없음)" : ' (+' + parseInt(e.currentTarget.getAttribute("price")).toLocaleString()+ '원)')
        }

        // 동일 카테고리 내에서 배열에 하나씩만 담기도록 함
        const find = selecList?.find((item) => {
            if(item.optionName === e.currentTarget.name){
                return item;
            }
        })

        if(find !== undefined){
            setSelecList(selecList?.filter((items) => items.optionName !== e.currentTarget.name))
        }
        setSelecList(oldArray => [...oldArray, obj ]);
    }

    //체크박스 클릭
    const inputClickCheckBox = (e) => {
        const obj = {
            "id" : e.currentTarget.getAttribute("menuId"),
            "optionName" : e.currentTarget.name,
            "name" :  e.currentTarget.getAttribute("menu"),
            "price" : e.currentTarget.getAttribute("price"),
            "optionDesc" : (e.currentTarget.name === '사이즈' ? `${e.currentTarget.name} ` : '') +
                            e.currentTarget.getAttribute("menu") + 
                            (e.currentTarget.getAttribute("price") === "0" ? "(추가금액 없음)" : ' (+' + parseInt(e.currentTarget.getAttribute("price")).toLocaleString() + '원)')
            
        }
        //체크해제 일경우 배열에서 빼기
        if(e.target.checked === false){
            setSelecList(selecList?.filter((items) => items.name !== e.currentTarget.getAttribute("menu")))
            return;
        }

        setSelecList(oldArray => [...oldArray, obj ]);
    }

    //총 주문가격
    const totalOrderPrice = () => {
        //사용자가 선택한 옵션
        try {
            const individualPrice = selecList?.map((items) => {
                return items.price;
            })
            //선택한 옵션 가격 더하기
            const totalIndividualPrice = individualPrice?.reduce((acc, cur) => {
                return Number(acc)+ Number(cur);
            },0)
            console.log("totalIndividualPrice",totalIndividualPrice, individualPrice, count, Number(foodDetail2.price));
            //(옵션 가격 + 음식가격) * 갯수
        
            const totalPrice = Number(count) * (Number(foodDetail2.price) + totalIndividualPrice);
            
            setTotalPrice(totalPrice)
        } catch {
            toast('메뉴 상세 조회에 실패 했습니다.');
            history.goBack();
        }
    }

    //수량 플러스
    const plusCount = () => {
        setCount((prev) =>  prev + 1);
    }
    //수량 마이너스
    const minusCount = () => {
        if(count === 1) return;
        setCount((prev) => prev - 1);
    }

    const goBack = () => {
        history.goBack()
        // userData.setMenuDetailBackFalg(true)
    }

    const PairIncreaseClick = (pairItem, index) => {
        let currItem = $(`.${index}_${pairItem.pargId}`);   
        let currText = currItem.text();
        console.log(`${index}_${pairItem.pargId}`);
        console.log(currItem);
        console.log(currText);

        if(parseInt(currText) < 10) {
            currItem.text(parseInt(currText) + 1);

            const menu = {
                prdtId : pairItem.pargId,
                prdtNm : pairItem.prdtNm,
                prdtImg : pairItem.mediaPath,
                price : parseInt(pairItem.price), // 제품가격
                totalPrice : parseInt(pairItem.price) * parseInt(currItem.text()),  // 총가격
                prdtCnt : parseInt(currItem.text()), // 음식갯수
                options : []
            }

            PairingMenuBasketPush(menu);
        }
    }

    const PairDecreaseClick = (pairItem, index) => {
        let currItem = $(`.${index}_${pairItem.pargId}`);   
        let currText = currItem.text();


        if(parseInt(currText) > 0) {
            currItem.text(parseInt(currText) - 1);
            
            console.log(currText);

            const selectPrice = userData.dataset.basketList.map((product) => {
                if(pairItem.pargId === product.prdtId) {
                    return { ...product, prdtCnt: product.prdtCnt - 1, totalPrice: product.price * (product.prdtCnt-1) }
                } else {
                    return product;
                }
            });
            userData.setBasketPut(selectPrice);

            if(parseInt(currText) === 1) {        
                const deleteProduct = userData.dataset.basketList.filter((item) => {
                    if(item.prdtCnt !== 0) {
                        return item;
                    }
                });
                userData.setBasketPut(deleteProduct);
                toast('선택하신 메뉴가 삭제되었습니다.');
            }
        } 
    }

    return (
        <>
            {isDataCheck(foodDetail2) && 
            <>
                <div className="content m_detail">
                    <div className="sec_wrap">
                        <div className="food_slider_wrap">
                            <button type="button" className="btn_back" onClick={goBack} ><span className="blind">뒤로가기</span></button>
                            <button type="button" className="btn_favorite" onClick={()=>fetchLike()}><span className="blind">좋아요</span></button>
                            {!isNullData(foodDetail2.mediaList) ?
                                <Swiper
                                    className="food_slider"
                                    // spaceBetween={2}
                                    // observeParents={true}
                                    // observer={true}
                                    pagination={{ 
                                        el: '.i_pagination', 
                                        type : 'fraction',
                                    }}
                                    slidesPerView="auto"
                                    loop={true}
                                    init
                                    
                                > 
                                            {/* 이미지, 동영상 개수만큼 슬라이드 */}
                                                <>
                                                    <div className="swiper-wrapper">
                                                    {
                                                        foodDetail2?.mediaList?.map((items, idx) => (
                                                            <SwiperSlide key={idx}>
                                                                {/* TODO 동영상, 이미지 구분 지어야 함, 사진이 없을경우? */}
                                                                {/* <video muted="" autoplay="">
                                                                    <source src="https://interactive-examples.mdn.mozilla.net/media/cc0-videos/flower.webm" type="video/webm">
                                                                </video> */}
                                                                <div className="food_thumb" style={!isNullData(foodDetail2.mediaList) ? {backgroundImage : `url('${items.mediaPath}`} : {backgroundImage : `url('${history.location.state}`}}></div>
                                                            </SwiperSlide>
                                                        ))
                                                    }
                                                    </div>
                                                </>
                                    <div className="i_pagination"></div>
                                </Swiper>
                                
                            : 
                                <Swiper
                                    className="food_slider"
                                    pagination={{ 
                                        el: '.i_pagination', 
                                        type : 'fraction',
                                    }}
                                    slidesPerView="auto"
                                    loop={true}> 
                                        <SwiperSlide >
                                            <div className="food_thumb" style={{backgroundImage : `url(${history.location.state}`}}></div>
                                        </SwiperSlide>
                                    <div className="i_pagination"></div>
                                </Swiper>
                            }
                        </div>

                        {/* <SwiperSlide >
                                <div className="food_thumb" style={{backgroundImage : `url('${process.env.REACT_APP_CONTEXT_PATH}/res/images/food_detail_dummy.png')`}}></div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="food_thumb">
                                <VodPlayer vodUrl={'https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'} />
                                </div>
                            </SwiperSlide >
                            <SwiperSlide >
                                <div className="food_thumb" style={{backgroundImage : `url('${process.env.REACT_APP_CONTEXT_PATH}/res/images/food_detail_dummy.png')`}}></div>
                            </SwiperSlide>
                            <SwiperSlide >
                                <div className="food_thumb" style={{backgroundImage : `url('${process.env.REACT_APP_CONTEXT_PATH}/res/images/food_detail_dummy.png')`}}></div>
                        </SwiperSlide > */}

                        <div className="food_desc">
                            <span className="i_title">{foodDetail2?.prdtNm}</span>
                            {(!isNullData(foodDetail2?.prdtInfo?.time) || !isNullData(foodDetail2?.prdtInfo?.spicy) || !isNullData(foodDetail2?.prdtInfo?.veganYn)) &&
                                <ul className="i_info">
                                    {!isNullData(foodDetail2?.prdtInfo?.time) &&
                                        <li className="ic_time">{foodDetail2?.prdtInfo?.time}</li>
                                    }
                                    {!isNullData(foodDetail2?.prdtInfo?.spicy) &&
                                        <li className="ic_hot">{foodDetail2?.prdtInfo?.spicy}</li>
                                    }
                                    {!isNullData(foodDetail2?.prdtInfo?.veganYn) &&
                                        <li className="ic_vegan">{foodDetail2?.prdtInfo?.veganYn === "Y" ? "비건 음식" : "비건 음식 아님"}</li>
                                    }
                                    
                                </ul>
                            }
                            <p className="i_desc">{foodDetail2?.prdtDesc}</p>
                            {(foodDetail2?.prdtInfo?.composition.length > 0 || foodDetail2?.allergy !== null) &&
                                <dl className="detail_info2">
                                    {foodDetail2?.prdtInfo?.composition.length > 0 &&
                                        <>
                                            <dt>구성</dt>
                                            <dd>{foodDetail2?.prdtInfo?.composition}</dd>
                                        </>
                                    }
                                    {foodDetail2?.allergy !== null ? 
                                        <>
                                            <dt><span className="ico_tips"><span className="blind">tip!</span></span></dt>
                                            <dd>{foodDetail2?.allergy}</dd>
                                        </>
                                        :
                                        <></>
                                    }
                                </dl>
                            }
                        </div>
                    </div>
                    
                    {isDataCheck(foodDetail2.price) &&
                        <div className="sec_wrap">
                            <div className="option_wrap">
                                <div className="opt_box default_box">
                                    <span className="i_title">가격</span>
                                    <span className="i_cont">{numberWithCommas(foodDetail2?.price)+'원'}</span>
                                </div>
                            </div>
                        </div>
                    }
                    {match.params.storeloc === "in" &&
                        <div className="sec_wrap">
                            <div className="option_wrap">
                                <div className="opt_box opt_quantity">
                                    <span className="i_title">수량</span>
                                    <div className="quantity_box">
                                        <button type="button" className="btn_decrease" onClick={minusCount}><span className="blind">감소</span></button>
                                        <span className="i_num">{count}</span>
                                        <button type="button" className="btn_increase bg_red" onClick={plusCount}><span className="blind">증가</span></button>
                                    </div>
                                </div>

                                {
                                    optionList?.map((items) => (
                                        <>
                                            {items.optnGubun === 'S' ?
                                            <div className="opt_box">
                                                <span className="i_title">{items.optionName}</span>
                                                <ul className="opt_select">
                                                    {items.optionItems.map((item, index) => (
                                                        <>
                                                            <li key={item.id}>
                                                                <div className="radio_box">
                                                                    <input className="radio_check"type="radio" onClick={(e) => inputClickRadioBox(e)} id={`${items.optionType}${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                    <label htmlFor={`${items.optionType}${index}`}>
                                                                        <span className="opt_txt">{item.name}</span>
                                                                        <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </>
                                                    ))}
                                                </ul>
                                            </div>
                                            : items.optnGubun === 'M' ?
                                            <div className="opt_box">
                                                <span className="i_title">{items.optionName}</span>
                                                <ul className="opt_select">
                                                    {items.optionItems.map((item, index) => (
                                                        <>
                                                            <li key={item.id}>
                                                                <div className="chk_box">
                                                                    <input className="chk" type="checkbox" onClick={(e) => inputClickCheckBox(e)} id={`optAddFood${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                    <label htmlFor={`optAddFood${index}`}>
                                                                        <span className="opt_txt">{item.name}</span>
                                                                        <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                        </>
                                                    ))}
                                                </ul>
                                            </div>
                                            :
                                            <></>
                                            }
                                    </>                     
                                    ))
                                }
                                
                            </div>
                        </div>
                    }
                    { !isNullData(foodDetail2.eatingTips) ?
                        <div className="sec_wrap">
                                <div className="tip_box">
                                    <span className="tip_title">더 맛있게 먹고싶다면?</span>
                                    <Swiper 
                                        className='tip_slider'
                                        observeParents={true}
                                        observer={true}
                                        slidesPerView="auto"
                                        loop={false}
                                    >
                                        {/* 이미지, 동영상 개수만큼 슬라이드 */}
                                        <div className="swiper-wrapper">
                                            {foodDetail2.eatingTips.tips.map((items,idx) => (
                                                <SwiperSlide>
                                                    <div className="tip_thumb" style={{backgroundImage : `url('${items.img}'`}}></div>
                                                    <p><span>0{idx + 1}</span>{items.memo}</p>
                                                </SwiperSlide>
                                            ))}
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                            
                            // <div className="sec_wrap">
                            //     <div className="tip_box">
                            //         <span className="tip_title">더 맛있게 먹고싶다면?</span>
                            //         <Swiper 
                            //             className='tip_slider'
                            //             observeParents={true}
                            //             observer={true}
                            //             slidesPerView="auto"
                            //             loop={false}
                            //         >
                                        
                            //             {/* 이미지, 동영상 개수만큼 슬라이드 */}
                            //             <div className="swiper-wrapper">
                            //                 <SwiperSlide>
                            //                     <div className="tip_thumb" style={{backgroundImage : `url('${process.env.REACT_APP_CONTEXT_PATH}/res/images/tip_dummy.png')`}}></div>
                            //                     <p><span>01</span>마파두부를 밥 위에 올려 또 다른 일품 요리로!</p>
                            //                 </SwiperSlide>
                            //                 <SwiperSlide>
                            //                     <div className="tip_thumb" style={{backgroundImage : `url('${process.env.REACT_APP_CONTEXT_PATH}/res/images/tip_dummy.png')`}}></div>
                            //                     <p><span>02</span>마파두부를 밥 위에 올려 또 다른 일품 요리로!</p>
                            //                 </SwiperSlide>
                            //                 <SwiperSlide>
                            //                     <div className="tip_thumb" style={{backgroundImage : `url('${process.env.REACT_APP_CONTEXT_PATH}/res/images/tip_dummy.png')`}}></div>
                            //                     <p><span>03</span>마파두부를 밥 위에 올려 또 다른 일품 요리로!</p>
                            //                 </SwiperSlide>
                            //             </div>
                            //         </Swiper>
                            //     </div>
                            // </div>
                            : <></>
                    }
                    
                    {fairingMenu?.pairingList?.length > 0 &&
                        <div className="sec_wrap">
                            <div className="tip_box">
                                <div className="suggest_box">
                                    <span className="i_title"><span className="pt_color">{`‘${foodDetail2?.prdtNm}’`}</span> 와 함께 하면 더 맛있어요!</span>
                                    <Swiper 
                                        className='suggest_slider'
                                        observeParents={true}
                                        observer={true}
                                        pagination={{ 
                                            clickable: true, 
                                            el: '.i_pagination_dot', 
                                        }}
                                        slidesPerView="auto"
                                        slidesPerGroup={3}
                                        loop={false}
                                    >
                                        {/* 이미지, 동영상 개수만큼 슬라이드 */}
                                        <div className="swiper-wrapper">
                                        {
                                            fairingMenu?.pairingList?.map((items, idx) => (
                                                <SwiperSlide key={idx}>
                                                    { (!isDataCheck(items.mediaPath)) ?
                                                        <span className="food_thumb">
                                                            {match.params.storeloc === "in" &&
                                                                <div className="quantity_box_xs">
                                                                    <div className="middle_inner">
                                                                        <button type="button" className="btn_decrease" onClick={()=> PairDecreaseClick(items, idx)}><span className="blind">감소</span></button>
                                                                        <span className={`i_num ${idx}_${items.pargId}`} count='0'>0</span>
                                                                        <button type="button" className="btn_increase" onClick={()=> PairIncreaseClick(items, idx)}><span className="blind">증가</span></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </span>
                                                    
                                                    :
                                                        <span className="food_thumb" style={{ backgroundImage: `url('${items.mediaPath}')`}}>
                                                            {match.params.storeloc === "in" &&
                                                                <div className="quantity_box_xs">
                                                                    <div className="middle_inner">
                                                                        <button type="button" className="btn_decrease" onClick={()=> PairDecreaseClick(items, idx)}><span className="blind">감소</span></button>
                                                                        <span className={`i_num ${idx}_${items.pargId}`} count='0'>0</span>
                                                                        <button type="button" className="btn_increase" onClick={()=> PairIncreaseClick(items, idx)}><span className="blind">증가</span></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </span>
                                                    }
                                                    <span className="food_name">{items.prdtNm}</span>
                                                    <span className="food_price">{numberWithCommas(items.price) + '원'}</span>
                                                </SwiperSlide>
                                            ))
                                        }
                                            
                                        </div>
                                        <div className="i_pagination_dot"></div>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            {match.params.storeloc === "in" &&
                <div className="sum_fixed">
                    <dl className="sum_total">
                        <dt>총 주문금액</dt>
                        <dd><em>{numberWithCommas(totalPrice)}</em>원</dd>
                    </dl>
                    <button type="button" className="btn_active btn_point" onClick={basketPut}><span className="total_num">{count + '+'}</span>장바구니에 담기</button>
                </div>
            }
         </>
        }
        </>
    )
});

export default DetailMenu;