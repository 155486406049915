import { observable } from 'mobx';

const initialDataSet = {
    storeInfo : {}
}

const userInfo = observable({
    dataset : initialDataSet,
    initialize() { 
        this.dataset = initialDataSet;
    },
    setList(data) {
        this.dataset = data;
    },
    getList() {
        return this.dataset;
    },
    setStoreInfo(data) {
        this.setList({
            ...this.dataset,
            storeInfo : data
        })
    }
});

export default userInfo;