import { layerPopup } from "./common";
import userData from "../store/userData";

export const touchUi = (function() {
	let bStartEvent = false;
	let startTouchY;
	let moveY;
	let innerscroll;
	let maxinnerscroll;
    return {
		"touchStartHandler": function(elem, id) {
    		elem.querySelector('.draggableItem').addEventListener('touchstart', function(event) {
				let target = event.target;
				while (target !== this) {
					target = target.parentElement;
				}
				if (!bStartEvent) {
					bStartEvent = true;
				}
				startTouchY = event.targetTouches[0].pageY;
				innerscroll = elem.querySelector('.innerscroll')?.getBoundingClientRect().height;
				maxinnerscroll = elem.querySelector('.innerscroll')?.scrollHeight;
			});			
		},
		"touchMovetHandler": function(elem, id) {
			elem.querySelector('.draggableItem').addEventListener('touchmove', function(event) {
				if (!bStartEvent) {
                	return;
				}
                event.stopPropagation();
				event.preventDefault();
				moveY = innerscroll - (event.targetTouches[0].pageY - startTouchY);
				if (moveY > maxinnerscroll){
					moveY = maxinnerscroll;
				}
                if(event?.targetTouches[0]?.pageY > 400){
                    if(elem?.querySelector('.innerscroll')?.style !== undefined){
                        elem.querySelector('.innerscroll').style.height = 0;
                    }
                }
                if (moveY < 0){
                    //콜백으로 안하면 팝업 렉? 먹음 확인필요
                    layerPopup.hide(id, () => {
                        userData.setDrgPopupHideFlag(true);
                    });
                    //스크롤로 팝업 닫을 때 데이터 초기화 필요 방법 1 : id block으로 처리
                }
                // console.log(event.targetTouches[0].pageY)
				elem.querySelector('.innerscroll').style.height = `${moveY}px`;
                // setScreenSize();
			});
		},
		"touchEndtHandler": function(elem) {
			elem.querySelector('.draggableItem').addEventListener('touchend', function(event) {
				bStartEvent = false;
			});
			elem.querySelector('.draggableItem').addEventListener('touchcancle', function(event) {
				bStartEvent = false;
			});
		},
		"init": function(container, id) {
			let layer = container;
			touchUi.touchStartHandler(layer, id);
			touchUi.touchMovetHandler(layer, id);
			touchUi.touchEndtHandler(layer, id);
		}
	};
}());

/* vh resize */
export function setScreenSize() {
    let vh = window.innerHeight * 0.01;
    document.querySelector('.innerscroll')?.style.setProperty('--vh', `${vh}px`);
}
setScreenSize();
window.addEventListener('resize', setScreenSize);
/*
draggableItem.addEventListener('touchstart', );
draggableItem.addEventListener('touchmove', touchUi.touchMovetHandler);
draggableItem.addEventListener('touchend', touchUi.touchEndtHandler);
draggableItem.addEventListener('touchcancle', touchUi.touchEndtHandler);
*/