import React from 'react'
import { observer } from 'mobx-react'
import store from '../../store';
import * as CONSTS from '../../const/index'
import SohoApi from '../../api/SohoApi';

const HeadTop = observer(({ location, match }) => {
    const { userInfo } = store;

    const ShareClick = () => {
        try {
            SohoApi.getAddInflowHistory({
                "storeId": match.params.storeId,
                "histType": 'S', //이력타입 (O: 온라인, S: 공유하기, L: 좋아요)
                "flowType": '', //histType O일 경우 - 온라인 종류 (N - 네이버, K - 카카오, I - 인스타, F - 페이스북, E - 기타)
                "flowPath": '' //flowType E일 경우 - 유입경로
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS){
                    console.log('성공');
                }
            }).catch((e) => {
                console.log(e);
            })
        } catch (e) {
            console.log(e);
        }

        if (navigator.share) {
            navigator.share({
                title: "씨식가",
                text: "씨식가 메뉴판",
                url: `${location.pathname}?utm_source=share&utm_medium=default&utm_campaign=out`,
            }).then(() => 
                console.log('공유 성공')
            ).catch((error) => 
                console.log('공유 실패', error)
            );
        }
    }

    return (
        <div className="hd_top">
            { userInfo.dataset.storeInfo.storeImg !== undefined &&
                <h1><span className="logo" style={{ backgroundImage: `url('${userInfo.dataset.storeInfo.storeImg}')` }}></span></h1>
            }
            {  match.params.storeloc === 'out' &&
                <a href={`${userInfo.dataset.storeInfo.storeMap}`} className="btn_map" target="_blank" rel="noreferrer"><span className="blind">지도(새창) 이동</span></a>
            }
            <button type="button" className="btn_share" onClick={() => ShareClick()}><span className="blind">공유하기</span></button>
        </div>
    )
});

export default HeadTop