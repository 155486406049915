import axios from 'axios'
import * as LocalStore from '../store/LocalStore';
import * as SessionStore from '../store/SessionStore';
import * as CONSTS from '../const';
import * as NativeBridge from '../utils/NativeBridge';

const instance = axios.create({
    baseURL: `//${process.env.REACT_APP_SOHO500TF_API_URL}`,
    timeout: 10000,
    headers: {'Accept': '*/*', 'Content-Type': 'application/json' }
    // headers: {'X-Custom-Header': 'foobar'}
});
instance.interceptors.request.use(
    function (config) {
        NativeBridge.getLoadingStart();
        return config;
    }, 
    function (error) {
        NativeBridge.getLodingEnd();
        return Promise.reject(error);
    }
);
instance.interceptors.response.use((response) => 
    {
        NativeBridge.getLodingEnd();
        if(response.data.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
            return response.data;
        } else {

            // 인증관련 오류시 팝업 처리 (팝업 확인 버튼 누를시 로그아웃 처리)
            const errCodeData = CONSTS.SOHOAPI_AUTH_LOGOUT_ERROR.find((item)=>item.code===response.data.retCode);
            if(typeof(errCodeData) !== "undefined") {
                /**
                 * error popup 
                 */
            }
            return response.data;
        }
    },
    async (error) => 
    {
        NativeBridge.getLodingEnd();
        return Promise.reject(error);
    }
);


/* header 세팅 샘플 */
export const setToken = (token) => {
    console.log(">>>>token:" + token);
    instance.defaults.headers.common['Authorization'] = token;
}


/* api sample */
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    /* 메뉴 상품 조회 */
    getProductList(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/productList',
            method: 'post',
            data: params
        })
    },
    /* 매장 주문 조회 */
    getStoreOrderInfo(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/storeOrder',
            method: 'post',
            data: params
        })
    },
    /* 라이브 메뉴 리스트 조회 */
    getLiveProductList(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/liveProductList',
            method: 'post',
            data: params
        })
    },

    /* 메뉴 상세 조회 */
    getProductDetailList(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/productDetail',
            method: 'post',
            data: params
        })
    },
    /* 페어링 리스트 조회 */
    getPairingList(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/pairingList',
            method: 'post',
            data: params
        })
    },
    /* 온라인 방문 조회 */
    getAddInflowHistory(params) {
        return instance({
            url: '/sohosvc/500tf/api/online/addInflowHistory',
            method: 'post',
            data: params
        })
    },
    /* 씨식가보드 조회 */
    getBoardInfoList(params) {
        return instance({
            url: '/sohosvc/500tf/api/boardInfo',
            method: 'post',
            data: params
        })
    },
    /* 매장 정보 조회 */
    getStoreInfo(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/storeInfo',
            method: 'post',
            data: params
        })
    },
    /* 한눈에 보기 */
    getOnceProductList(params) {
        return instance({
            url: '/sohosvc/500tf/api/offline/onceProductList',
            method:'post',
            data:params
        })
    }
   
}