import React, { useEffect, useState } from 'react';
import { Route } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from '../../store';
import SohoApi from '../../api/SohoApi';
import Foot from '../foot/Foot';
import HeadTop from '../head/HeadTop';
import { tabUi } from '../../libs/tab';
import { isNullData, menuBasketPush, numberWithCommas } from '../../common/common';
import $ from 'jquery';
import MoveMenu from './components/MoveMenu';
import { layerPopup, toast, webUI } from '../../libs/common';
import { setScreenSize, touchUi } from '../../libs/touchUi';
import * as CONSTS from '../../const/index'

const Menu = observer(({ location, history, match }) => {

    
    const { userData } = store;
    const [menuList, setMenuList] = useState(null);
    const [totalPrice, setTotalPrice] = useState(0)
    const [optionData, setOptionData] = useState(null)
    const [selecList, setSelecList] = useState([]);
    const [count, setCount] = useState(1);

    useEffect(() => {
        tabUi.initSubTab(document.getElementById('tab_1depth'));
        if(userData.dataset.menuTab === "fullmenu"){
            userData.setMenuTabList("fullmenu");
        }
    }, [])

    useEffect(() => {
        if(userData.dataset.drgPopupHideFlag){
            setTotalPrice(0);
            setSelecList([])
            setCount(1)
            setOptionData(null)
            userData.setDrgPopupHideFlag(false);
        }
    },[userData.dataset.drgPopupHideFlag])

    useEffect(() => {
        if(optionData !== null){
            totalOrderPrice();
        }
    },[count,selecList,optionData])

    useEffect(() => {
        touchUi.init(document.getElementById('option_menu_select_popup'), "#option_menu_select_popup");
    },[optionData])

    useEffect(() => {
        if(match.params.storeId) {
            async function fetchProductList() {
                await getProductList(match.params.storeId);
            }
            fetchProductList();
        }
    }, [match.params.storeId])
        
    useEffect(() => {
        let menuHeight = document.querySelector(".hd_fixed_wrap").offsetHeight  

        if(userData.dataset.menuTab === "fullmenu"){
            layerPopup.show('#popup_menu_tutorial');
        } else {
            console.log('top',$(`#${userData.dataset.menuTab}`).offset()?.top);
            let container = document.getElementById('tab_1depth');
            if(document.getElementById("popup_menu_tutorial").style.display === "block"){
                layerPopup.hide('#popup_menu_tutorial');
            }
            setTimeout(() => {  
                if (!container.classList.contains('active')) {
                    let indexNum =""
                    let activeSearch = document.getElementsByClassName("tab_item");
                    Array.from(activeSearch).forEach((items, idx) => {
                        if(items.className === "tab_item active"){
                            indexNum = idx;
                        }    
                    })
                    webUI.animatedScrollTo(container, (container.querySelectorAll('li')[indexNum]?.offsetLeft 
                    + container.querySelectorAll('li')[indexNum]?.clientWidth * 0.5) - (container.clientWidth * 0.5), 300);
                    userData.setMenuDetailBackFlag(false);
                }
                window.scrollTo({ left: 0, top: $(`#${userData.dataset.menuTab}`).offset()?.top - menuHeight, behavior: "smooth" });
            }, 200)
        }
    },[userData.dataset.menuTab])
   
    useEffect(() => {
        if(optionData !== null) defaultCheck()
    },[optionData])

    //해당 메뉴로 이동
    const goMenuDetail = (e,storeId, img, catgId) => {
        console.log("catgId",catgId);
        userData.setMenuTabList(catgId);
        const prdtId = e.currentTarget.id
        history.push(`/detailmenu/${match.params.storeloc}/${prdtId}/${storeId}?tblNum=${userData.dataset.tblNum}`, img );
        e.preventDefault();
    }

    //장바구니 담기 클릭 했을 때 
    const optionCheck = () => {
        let options = [];
        const radioCheck = document.getElementsByClassName("radio_check");
        const check = document.getElementsByClassName("chk");

        Array.from(radioCheck).forEach((items,idx) =>{
            if(items.checked === true){
                const obj = {
                    "id" : items.getAttribute("menuId"),
                    "optionName" : items.name,
                    "price" : items.getAttribute("price"),
                    "name" : items.getAttribute("menu"),
                    "optionDesc" : (`${(items.name === '업그레이드' ? '' : items.name)} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                    parseInt(items.getAttribute("price")).toLocaleString() + '원)'))
                }
                options.push(obj);
            }
        })

        Array.from(check).forEach((items,idx) => {
            if(items.checked === true){
                const obj = {
                    "id" : items.getAttribute("menuId"),
                    "optionName" : items.name,
                    "price" : items.getAttribute("price"),
                    "name" : items.getAttribute("menu"),
                    "optionDesc" : (`${(items.name === '업그레이드' ? '' : items.name)} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                    parseInt(items.getAttribute("price")).toLocaleString() + '원)'))
                }
                options.push(obj);
            }
        })
        return options;
    }
    
    //전체리스트 불러오기
    const getProductList = async() => {
        try {
                await SohoApi.getProductList({
                    storeId: match.params.storeId
                }).then((rst) => {
                    if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                        setMenuList(rst.data);
                    }
                }).catch((e) => {
                    toast('메뉴 정보를 불러오는데 실패 했습니다.');
                })
        } catch(e) {
            toast('메뉴 정보를 불러오는데 실패 했습니다.');
        }
    }
    
    const categoryListTapClick = (e, menutab) => {
        userData.setMenuTabList(menutab);
    }

    const optionsPopup = (e) => {
        if(match.params.storeloc === "in"){
            document.querySelector('.innerscroll').style.height = 'auto';
            // layerPopup.show("#option_menu_select_popup");
        }
        
        setScreenSize();
        // tabUi.initSubTab(document.getElementById('tab_1depth'));
        setOptionData({
            id: e.currentTarget.getAttribute("id"),
            name : e.currentTarget.getAttribute("name"),
            price : e.currentTarget.getAttribute("price"),
            desc : e.currentTarget.getAttribute("desc"),
            options : JSON.parse(e.currentTarget.getAttribute("options")),
            imgPath : e.currentTarget.getAttribute("imgPath")
        })
        layerPopup.show("#option_menu_select_popup");

        e.preventDefault();
		e.stopPropagation();
    }

    const basketPut = async (e) => {
        const options = await optionCheck();

        //menu.js에서 상품리스트 중 장바구니 클릭하면 페이지 넘어가는 현상 막기위함
        e.preventDefault();
		e.stopPropagation();

        //store에 넣을 데이터를 가져온다
        const menu = {
            prdtId : optionData.id,
            tag : "",
            prdtNm : optionData.name,
            prdtDesc : optionData.desc,
            allergy : "",
            ingredients: "",
            prdtInfo: "",
            eatingTipsL: "",
            prdtImg : optionData.imgPath, //imgPath
            // vodPath : foodDetail.vodPath,
            // useType : foodDetail.useType,
            price : totalPrice / count, // 제품가격
            totalPrice : totalPrice, // 총가격
            prdtCnt : count, // 음식갯수
            // options : selecList.length > 0 ? selecList : []
            options : options
        };

        //장바구니 담기 
        await menuBasketPush(menu, count);
        dataReset();
        //공통으로 쓰일지 몰라 따로 뺌
        // menuBasketPush(menu);
    }

    //첫 화면 진입시 default 체크
    const defaultCheck = () => {
        //옵션에서 단일 선택만 불러오기
        if(optionData.options === null) return;
        const searchOption = optionData?.options.options.map((items) => { 
            if(items.optnGubun === "S"){
                console.log("items.optionType", items.optionType)
                return items.optionType;
            }
        })
        //단일선택 옵션이 있을경우에만 로직 진행
        if(searchOption?.length > 0){
            searchOption.forEach((items, idx) => {
                if(items !== undefined){

                    //해당 각 id의 첫번째 항목만 checked = true
                    const firstItem = document.getElementById(items + "0");
                    console.log("firstItem",firstItem);
                    firstItem.checked = true;

                    //selecList에 담기
                    const obj = {
                        "id" : firstItem.getAttribute("menuId"),
                        "optionName" : firstItem.name,
                        "price" : firstItem.getAttribute("price"),
                        "name" :  firstItem.getAttribute("menu"),
                        "optionDesc" : (firstItem.name === '사이즈' ? `${firstItem.name} ` : '') +
                                        firstItem.getAttribute("menu") + 
                                        (firstItem.getAttribute("price") === '0' ? "(추가금액 없음)" : ' (+' + parseInt(firstItem.getAttribute("price")).toLocaleString() + '원)')
                    }
                    setSelecList(oldArray => [...oldArray, obj ])
                }
            })
        }

    }

    const totalOrderPrice = () => {
        //사용자가 선택한 옵션
        const individualPrice = selecList?.map((items) => {
            return items.price;
        })
        //선택한 옵션 가격 더하기
        const totalIndividualPrice = individualPrice?.reduce((acc, cur) => {
            return Number(acc)+ Number(cur);
        },0)
        //(옵션 가격 + 음식가격) * 갯수
        const totalPrice = count * (Number(optionData.price) + totalIndividualPrice);
        setTotalPrice(totalPrice)
    }


    const inputClickRadioBox = (e) => {
        //총 주문금액을 책정하기 위해 가격과 메뉴이름을 가져온다
        const obj = {
            "id" : e.currentTarget.getAttribute("menuId"),
            "optionName" : e.currentTarget.name,
            "name" :  e.currentTarget.getAttribute("menu"),
            "price" : e.currentTarget.getAttribute("price"),
            "optionDesc" : (e.currentTarget.name === '사이즈' ? `${e.currentTarget.name} ` : '') + 
                            e.currentTarget.getAttribute("menu") + 
                            (e.currentTarget.getAttribute("price") === "0" ? "(추가금액 없음)" : ' (+' + parseInt(e.currentTarget.getAttribute("price")).toLocaleString()+ '원)')
        }
        console.log("obj",obj);
        // 동일 카테고리 내에서 배열에 하나씩만 담기도록 함
        const find = selecList?.find((item) => {
            if(item.optionName === e.currentTarget.name){
                return item;
            }
        })

        if(find !== undefined){
            setSelecList(selecList?.filter((items) => items.optionName !== e.currentTarget.name))
        }
        setSelecList(oldArray => [...oldArray, obj ]);
    }

    //체크박스 클릭
    const inputClickCheckBox = (e) => {
        const obj = {
            "id" : e.currentTarget.getAttribute("menuId"),
            "optionName" : e.currentTarget.name,
            "name" :  e.currentTarget.getAttribute("menu"),
            "price" : e.currentTarget.getAttribute("price"),
            "optionDesc" : (e.currentTarget.name === '사이즈' ? `${e.currentTarget.name} ` : '') +
                            e.currentTarget.getAttribute("menu") + 
                            (e.currentTarget.getAttribute("price") === "0" ? "(추가금액 없음)" : ' (+' + parseInt(e.currentTarget.getAttribute("price")).toLocaleString() + '원)')
            
        }
        //체크해제 일경우 배열에서 빼기
        if(e.target.checked === false){
            setSelecList(selecList?.filter((items) => items.name !== e.currentTarget.getAttribute("menu")))
            return;
        }

        setSelecList(oldArray => [...oldArray, obj ]);
    }

    //수량 플러스
    const plusCount = () => {
        if(count === 10) return;
        setCount((prev) =>  prev + 1);
    }
    //수량 마이너스
    const minusCount = () => {
        if(count === 1) return;
        setCount((prev) => prev - 1);
    }

    const dataReset = () => {
        console.log("totalprice",totalPrice);
        setTotalPrice("");
        setSelecList([]);
        setCount(1)
        setOptionData(null)
        layerPopup.hide("#option_menu_select_popup")
    }

    return (
        <>
            <div className="content menu_all">
                <div className="hd_wrap hd_fixed_wrap">
                    <Route component={HeadTop} location={location} history={history} match={match} />
                    <div id="tab_1depth" className="tab_1depth">
                        <ul className="tab_link">
                            <li className={"tab_item link_full_menu" + (userData.dataset.menuTab === "fullmenu" ? ' active' : '')} name="movemenu" onClick={(e) => categoryListTapClick(e, 'fullmenu')}><span>한 눈에 보기</span></li>
                            {menuList?.categoryList.map((items, idx) => (
                                <>
                                    <li key={idx} className={'tab_item' + (userData.dataset.menuTab === items.catgId ? ' active' : '') } name={items.catgId} onClick={(e) => categoryListTapClick(e, items.catgId)}><span name={items.catgId}>{items.catgNm}</span></li>
                                </>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="fix_container">
                    { userData.dataset.menuTab === 'fullmenu' ?
                        <MoveMenu menuList={menuList} storeId={match.params.storeId} storeloc={match.params.storeloc} />
                    :
                        (menuList?.categoryList.map((items, idx) => (
                            <div key={idx} className="menu_box" id={items.catgId}>
                                <span className="lg_cate">{items.catgNm}</span>
                                <p className="cate_desc">{items?.catgDesc}</p>
                                <ul >
                                    {items.productList.map((item, idx) =>(
                                        <li id={item.prdtId} onClick={(e)=>goMenuDetail(e, match.params.storeId, item.mediaPath, items.catgId)}>
                                            {/* <span className="food_thumb" style={{ backgroundImage: `url('./res/images/food_dummy1.png')` }}></span> */}
                                                <>
                                                    <span className="food_thumb" style={item.mediaPath !== null ? { backgroundImage: `url('${item.mediaPath}')` } : {}}></span>
                                                </>
                                                <div className="middle_inner" >
                                                    {!isNullData(item.tag) &&
                                                        <>
                                                            {
                                                                item.tag === 'SIGNATURE' ?
                                                                    <span className="i_tag signature"><span className="blind">시그니쳐</span></span>
                                                                : item.tag === "HOT" ?
                                                                    <span className="i_tag i_popular"><span className="blind">인기</span></span>
                                                                : item.tag === "NEW" ?
                                                                    <span className="i_tag i_new"><span className="blind">New</span></span>
                                                                : item.tag === "REORDER" ?
                                                                    <span className="i_tag i_reorder"><span className="blind">재주문 많음</span></span>
                                                                : <></>
                                                            }
                                                        </>
                                                    }
                                                    <span className="food_name">{item.prdtNm}</span>
                                                    <span className="food_price">{numberWithCommas(item.price) + '원'}</span>
                                                </div>
                                                {
                                                    match.params.storeloc === "in" &&
                                                        <button 
                                                            type="button" 
                                                            className="btn_basket" 
                                                            id={item.prdtId} 
                                                            name={item.prdtNm} 
                                                            price={item.price}
                                                            imgPath={item.mediaPath}
                                                            desc={item.desc} 
                                                            options={JSON.stringify(item.options)}
                                                            onClick={(e) => optionsPopup(e)} 
                                                        >
                                                            <span className="blind">담기</span>
                                                        </button>
                                                }
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))
                        )       
                    }
                    <Route component={Foot} history={history} location={location} match={match} />
                </div>
            </div>
            <div className="modal fadeIn modal_move" id="popup_menu_tutorial">
                <div className="dim" onClick={()=>layerPopup.hide('#popup_menu_tutorial')}></div>
                <div className="modal_inner" onClick={()=>layerPopup.hide('#popup_menu_tutorial')}>
                    <span className="move_img"></span>
                    <p className="move_desc">화면을 움직이고 <span className="pt_color">메뉴도 선택</span>해보세요!</p>
                </div>
            </div>
{/*  
    $(document).ready(function() {

        if($('.suggest_slider').length > 0){
            var foodSuggestSlider = new Swiper('.suggest_slider', {
                observer: true, // 옵션 필수
                observeParents: true, // 옵션 필수
                slidesPerView: 'auto',
                slidesPerGroup: 3,
                pagination: {
                    el: '.i_pagination_dot',
                },
            });
        };

    });
*/}

            {/* 옵션선택 팝업 */}
            <div className="modal fadeInUp modal_option_wrap" id="option_menu_select_popup"> 
                <div className="dim bg_transparent" onClick={() => dataReset()}></div>
                <div className="modal_inner modal_option">
                    <button type="button" className="pop_close" onClick={() => dataReset()}><span className="blind">닫기</span></button>
                    <div className="modal_touch">
                        <div className="draggableItem">
                            <span className="food_name">{optionData?.name}</span>
                            <p className="food_desc">{optionData?.desc}</p>
                        </div>
                        <div className="innerscroll">
                            <div className="option_wrap">
                                <div className="opt_box opt_quantity">
                                    <span className="i_title">수량</span>
                                    <div className="quantity_box">
                                        <button type="button" className="btn_decrease" onClick={minusCount}><span className="blind">감소</span></button>
                                        <span className="i_num">{count}</span>
                                        <button type="button" className="btn_increase bg_red" onClick={plusCount}><span className="blind">증가</span></button>
                                    </div>
                                </div>
                                {optionData?.options !== null &&
                                        optionData?.options?.options?.map((items, idx) => (
                                            items.optnGubun === 'S' ?
                                                <div className="opt_box">
                                                    <span className="i_title">{items.optionName}</span>
                                                    <ul className="opt_select">
                                                        {items.optionItems.map((item, index) => (
                                                            <>
                                                                <li key={item.id}>
                                                                    <div className="radio_box">
                                                                        <input className="radio_check"type="radio" onClick={(e) => inputClickRadioBox(e)} id={`${items.optionType}${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                        <label htmlFor={`${items.optionType}${index}`}>
                                                                            <span className="opt_txt">{item.name}</span>
                                                                            <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))}
                                                    </ul>
                                                </div>
                                            : items.optnGubun === 'M' ?
                                                <div className="opt_box">
                                                    <span className="i_title">{items.optionName}</span>
                                                    <ul className="opt_select">
                                                        {items.optionItems.map((item, index) => (
                                                            <>
                                                                <li key={item.id}>
                                                                    <div className="chk_box">
                                                                        <input className="chk"type="checkbox" onClick={(e) => inputClickCheckBox(e)} id={`optAddFood${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                        <label htmlFor={`optAddFood${index}`}>
                                                                            <span className="opt_txt">{item.name}</span>
                                                                            <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))}
                                                    </ul>
                                                </div>
                                            : <></>
                                        ))}
                            </div>
                        </div>
                                
                        {/* <!-- 총 주문금액 --> */}
                         <div className="sum_fixed">
                            <dl className="sum_total">
                                <dt>총 주문금액</dt>
                                <dd><em>{numberWithCommas(totalPrice)}</em>원</dd>
                            </dl>
                            <button type="button" onClick={basketPut} className="btn_active btn_point"><span className="total_num">{count + '+'}</span>장바구니에 담기</button>
                        </div>
                    </div>
                </div>
             </div>  

            {/* <>
            <div className="modal fadeInUp modal_option_wrap" id="option_menu_select_popup"> 
                <div className="dim bg_transparent" onClick={dataReset}></div>
                <div className="modal_inner modal_option">
                    <button type="button" className="pop_close" onClick={dataReset}><span className="blind">닫기</span></button>
                    <div className="modal_touch">
                        <div className="draggableItem">
                            <span className="food_name">{optionData?.name}</span> */}
                            {/* <span className="food_price">{numberWithCommas(optionData?.price)}원</span> */}
                            {/* <p className="food_desc">{optionData?.desc}</p>
                        </div>
                        <div className="innerscroll">
                            <div className="option_wrap">
                                <div className="opt_box opt_quantity">
                                    <span className="i_title">수량</span>
                                    <div className="quantity_box">
                                        <button type="button" className="btn_decrease" onClick={minusCount}><span className="blind">감소</span></button>
                                        <span className="i_num">{count}</span>
                                        <button type="button" className="btn_increase bg_red" onClick={plusCount}><span className="blind">증가</span></button>
                                    </div>
                                </div> */}
                                
                                {/* {optionData?.options !== null &&
                                                    optionData?.options?.options?.map((items, idx) => (
                                                        items.optnGubun === 'S' ?
                                                            <div className="opt_box">
                                                                <span className="i_title">{items.optionName}</span>
                                                                <ul className="opt_select">
                                                                    {items.optionItems.map((item, index) => (
                                                                        <>
                                                                            <li key={item.id}>
                                                                                <div className="radio_box">
                                                                                    <input className="radio_check"type="radio" onClick={(e) => inputClickRadioBox(e)} id={`${items.optionType}${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                                    <label htmlFor={`${items.optionType}${index}`}>
                                                                                        <span className="opt_txt">{item.name}</span>
                                                                                        <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        : items.optnGubun === 'M' ?
                                                            <div className="opt_box">
                                                                <span className="i_title">{items.optionName}</span>
                                                                <ul className="opt_select">
                                                                    {items.optionItems.map((item, index) => (
                                                                        <>
                                                                            <li key={item.id}>
                                                                                <div className="chk_box">
                                                                                    <input className="chk"type="checkbox" onClick={(e) => inputClickCheckBox(e)} id={`optAddFood${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                                    <label htmlFor={`optAddFood${index}`}>
                                                                                        <span className="opt_txt">{item.name}</span>
                                                                                        <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        : <></>
                                                    ))} */}

                            {/* </div>
                        </div   > */}
                        {/* <div className="sum_fixed">
                            <dl className="sum_total">
                                <dt>총 주문금액</dt>
                                <dd><em>{numberWithCommas(totalPrice)}</em>원</dd>
                            </dl>
                            <button type="button" onClick={basketPut} className="btn_active btn_point"><span className="total_num">{count + '+'}</span>장바구니에 담기</button>
                        </div>
                    </div>
                </div> */}
            {/* </div> */}



        </>
    )
});

export default Menu;