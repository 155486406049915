import React, {useState, useEffect} from 'react';
import { observer } from 'mobx-react';
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from "swiper";
import $ from 'jquery'
import { Route } from 'react-router-dom';
import store from '../../store';
import { layerPopup, toast } from '../../libs/common';
import SohoApi from '../../api/SohoApi';
import { SOHOAPI_RETCODE_SUCCESS } from '../../const';
import Foot from '../foot/Foot';
import { PairingMenuBasketPush, isDataCheck, menuBasketPush } from '../../common/common';
import HeadTop from '../head/HeadTop'
import { touchUi } from '../../libs/touchUi';

SwiperCore.use([ Navigation, Autoplay ]);

const Live = observer(({ match, location, history }) => {

    const [liveMenuList, setLiveMenuList] = useState([]);
    const [selectMenu, setSelectMenu] = useState(null);
    const [selectMenuCnt, setSelectMenuCnt] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const { userData } = store; 

    let autoPlayDelay = 7000;
    let slidersCount = 0;
    let widthParts = 100 / slidersCount;
    
    useEffect(() => {
        if(match.params.storeId) {
            async function fetchLiveProductList() {
                await getLiveProductList(match.params.storeId);
            }
            fetchLiveProductList();
        }
    }, [match.params.storeId]);

    useEffect(() => {
        var checkedPrice = [];
        $("input[name='optSelect']:checked").each(function() {
            var chkvalue = $(this).val();
            checkedPrice.push(parseInt(chkvalue));
        })
        var total = ((parseInt(selectMenu?.price) + checkedPrice.reduce((a, b) => a + b, 0)) * selectMenuCnt)
        setTotalPrice(total);
    }, [selectMenuCnt])
    
    useEffect(() => {
        touchUi.init(document.getElementById('option_live_select_popup'), "#option_live_select_popup");

        if(selectMenu !== null && selectMenu.options !== null) {
            document.getElementById('optSelectSize1').checked = true;
        }
    }, [selectMenu])

    useEffect(() => {
        if(isDataCheck(liveMenuList)) {
            slidersCount = liveMenuList.length;
            widthParts = 100 / liveMenuList.length;
            for(let i=0; i < slidersCount; i++){
                $('.swiper-progress-bar .progress-sections').append('<span></span>');
            }
            initProgressBar(300);
        }
    }, [liveMenuList])

    useEffect(() => {
        if(userData.dataset.drgPopupHideFlag){
            setTotalPrice(0);
            setSelectMenuCnt(1)
            setSelectMenu(null);
            userData.setDrgPopupHideFlag(false);
        }
    },[userData.dataset.drgPopupHideFlag])

    const getLiveProductList = async(refStoreId) => {
        try {
            await SohoApi.getLiveProductList({
                storeId: refStoreId
            }).then((rst) => {
                if(rst.retCode === SOHOAPI_RETCODE_SUCCESS) {
                    setLiveMenuList(rst.data.liveProductList);
                }
            }).catch((e) => {
                console.log(e);
                toast('라이브 메뉴 정보를 가져오는데 실패했습니다.');
            })
        } catch (e) {
            console.log(e);
            toast('라이브 메뉴 정보를 가져오는데 실패했습니다.');
        }
    }

    const chgSlideHandler = (activeIndex, speed) => {
		let progress = $('.swiper-progress-bar .progress');
        console.log(activeIndex);
        // console.log(speed);

        const currDropmenu = $(`.better_menu${activeIndex+1}`);
        if(currDropmenu.hasClass('on')) {
            $(`.btn_sound`).css('top', '31.108rem');
        } else {
            $(`.btn_sound`).css('top', '13.846rem');
        }

        if(activeIndex === 0 && !progress.parent().is('.stopped')) {
            progress.stop();
            initProgressBar(speed);
		}

        if(progress.parent().is('.stopped')) {
            widthParts = 100 / liveMenuList.length;
			progress.animate({
				'width': Math.round(widthParts * (activeIndex + 1)) + '%'
			}, speed, 'linear');
		}
    };

    const touchSlideHandler = () => {
        $('.swiper-progress-bar .progress').stop().parent().addClass('stopped');
    }

    const initProgressBar = (speed) => {
        slidersCount = liveMenuList.length;
        let calcProgress = (slidersCount-1) * (autoPlayDelay + speed);
		let progress = $('.swiper-progress-bar .progress');

        calcProgress += autoPlayDelay;
        console.log(calcProgress);

        progress.css('width', '0%');


		$('.swiper-progress-bar .progress').animate({
			width: '100%'
		}, calcProgress, 'linear');
    }

    const speakerOnOff = () => {
        if($(`.btn_sound`).hasClass('off')) {
            $('#videoplayer').prop('muted', false);
            $(`.btn_sound`).removeClass('off');
        } else {
            $('#videoplayer').prop('muted', true);
            $(`.btn_sound`).addClass('off');
        }
    }
    
    const pairingListClick = (index) => {
        const currDropmenu = $(`.better_menu${index}`);
        if(currDropmenu.hasClass('on')) {
            $('.btn_sound').css('top', '13.846rem');
            currDropmenu.removeClass('on');
        } else {
            $('.btn_sound').css('top', '31.108rem');
            currDropmenu.addClass('on');
        }
    }

    const IncreaseClick = () => {
        if(selectMenuCnt < 10) {
            setSelectMenuCnt(selectMenuCnt => selectMenuCnt + 1);
        }
    };

    const PairIncreaseClick = (pairItem, index, liveindex) => {
        let currItem = $(`.${liveindex}_${index}_${pairItem.pargId}`);   
        let currText = currItem.text();

        if(parseInt(currText) < 10) {
            currItem.text(parseInt(currText) + 1);

            const menu = {
                prdtId : pairItem.pargId,
                prdtNm : pairItem.prdtNm,
                prdtImg : pairItem.mediaPath,
                price : parseInt(pairItem.price), // 제품가격
                totalPrice : (parseInt(pairItem.price) * parseInt(currItem.text())),  // 총가격
                prdtCnt : parseInt(currItem.text()), // 음식갯수
                options : []
            }

            PairingMenuBasketPush(menu);
        }
    }

    const DecreaseClick = () => {
        if(selectMenuCnt > 1) {
            setSelectMenuCnt(selectMenuCnt => selectMenuCnt - 1);
        }
    };

    const PairDecreaseClick = (pairItem, index, liveindex) => {
        let currItem = $(`.${liveindex}_${index}_${pairItem.pargId}`);   
        let currText = currItem.text();


        if(parseInt(currText) > 0) {
            currItem.text(parseInt(currText) - 1);
            
            const selectPrice = userData.dataset.basketList.map((product) => {
                if(pairItem.pargId === product.prdtId) {
                    return { ...product, prdtCnt: product.prdtCnt - 1, totalPrice: product.price * (product.prdtCnt-1) }
                } else {
                    return product;
                }
            });
            userData.setBasketPut(selectPrice);

            if(parseInt(currText) === 1) {        
                const deleteProduct = userData.dataset.basketList.filter((item) => {
                    if(item.prdtCnt !== 0) {
                        return item;
                    }
                });
                userData.setBasketPut(deleteProduct);
                toast('선택하신 메뉴가 삭제되었습니다.');
            }
        }
    }

    const SelectItem = (item, __prdtCnt) => {


        const selectMenu = {
            prdtId : item.prdtId,
            prdtNm : item.prdtNm,
            prdtDesc : item.prdtDesc,
            price : parseInt(item.price), // 제품가격
            options : item.options,
            prdtCnt : 1
        }
        console.log(selectMenu);
        setTotalPrice(parseInt(item.price));
        setSelectMenu(item);
        document.querySelector('.innerscroll').style.height = 'auto';
        layerPopup.show('#option_live_select_popup');
    }

    //장바구니 담기 클릭 했을 때 
    const optionCheck = () => {
        let options = [];
        const radioCheck = document.getElementsByClassName("radio_check");
        const check = document.getElementsByClassName("chk");

        Array.from(radioCheck).forEach((items,idx) =>{
            console.log(items);
            if(items.checked === true){
                const obj = {
                    "id" : items.getAttribute("menuId"),
                    "optionName" : items.getAttribute("optionName"),
                    "price" : items.getAttribute("price"),
                    "name" : items.getAttribute("menu"),
                    
                    "optionDesc" : (`${(items.getAttribute("optionName") === '업그레이드' ? '' : items.getAttribute("optionName"))} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                    parseInt(items.getAttribute("price")).toLocaleString() + '원)'))
                }
                options.push(obj);
            }
        })

        Array.from(check).forEach((items,idx) => {
            if(items.checked === true){
                const obj = {
                    "id" : items.getAttribute("menuId"),
                    "optionName" : items.getAttribute("optionName"),
                    "price" : items.getAttribute("price"),
                    "name" : items.getAttribute("menu"),
                    "optionDesc" : (`${(items.getAttribute("optionName") === '업그레이드' ? '' : items.getAttribute("optionName"))} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                    parseInt(items.getAttribute("price")).toLocaleString() + '원)'))
                }
                options.push(obj);
            }
        })
        return options;
    }

    const BasketClickHandler = () => {
        const options = optionCheck();

        const menu = {
            prdtId : selectMenu.prdtId,
            tag : '',
            prdtNm : selectMenu.prdtNm,
            prdtDesc : selectMenu.prdtDesc,
            allergy : '',
            ingredients: '',
            prdtInfo: '',
            eatingTipsL: '',
            prdtImg : selectMenu.prdtMediaPath,
            price : totalPrice / selectMenuCnt, // 제품가격
            totalPrice : totalPrice, // 총가격
            prdtCnt : selectMenuCnt, // 음식갯수
            options : options
        }
        menuBasketPush(menu, selectMenuCnt);

        layerPopup.hide('#option_live_select_popup', ()=> {
            setSelectMenu(null);
            setSelectMenuCnt(1);
            setTotalPrice(0);
        });
    }

    const MenuLikeClick = async(prdtId, index) => {
        if($(`.favorite${index}`).hasClass('on')) {
            $(`.favorite${index}`).removeClass('on')
        } else {
            try {
                SohoApi.getAddInflowHistory({
                    storeId: match.params.storeId,
                    histType: 'L',
                    flowType: '',
                    flowPath: prdtId
                }).then((rst) => {
                    if(rst.retCode === SOHOAPI_RETCODE_SUCCESS) {
                        $(`.favorite${index}`).addClass('on');
                        toast('좋아요');
                    }
                }).catch((e) => {
                    toast('좋아요 처리에 실패했습니다.');
                });
            } catch {
                toast('좋아요 처리에 실패했습니다.');
            }
        } 
    }

    // 라디오박스 클릭
    const OptionClick = (e) => {
        var checkedPrice = [];
        $("input[name='optSelect']:checked").each(function() {
            var chkvalue = $(this).val();
            checkedPrice.push(parseInt(chkvalue));
        })
        console.log(checkedPrice);
        var totalPrice = ((parseInt(selectMenu?.price) + checkedPrice.reduce((a, b) => a + b, 0)) * selectMenuCnt);

        setTotalPrice(totalPrice);
    }

    const PopupClose = () => {
        layerPopup.hide("#option_live_select_popup", ()=> {
            setSelectMenu(null);
            setSelectMenuCnt(1);
            setTotalPrice(0);
        });
    }

    return (
        <>  
            <div className="content live_menu">
                <div className="live_content">
                    <div className="hd_wrap">
                        <Route component={HeadTop} location={location} history={history} match={match} />
                    </div>
                    <Swiper
                        className="live_slider"
                        autoplay={{
                            delay: autoPlayDelay,
                            disableOnInteraction: false
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev' 
                        }}
                        observer
                        observeParents
                        init={true}
                        loop={false}
                        onSlideChange={(swiper)=> chgSlideHandler(swiper.realIndex, swiper.params.speed)}
                        onTouchMove={()=>touchSlideHandler()}>
                            <button type="button" className='btn_sound off' onClick={()=>speakerOnOff()}><span className="blind">사운드</span></button>
                            { isDataCheck(liveMenuList) ?
                                liveMenuList.map((item, liveindex) => (
                                    <>
                                        <SwiperSlide>
                                            { isDataCheck(item.liveMediaPath) ?
                                                <video muted autoPlay loop data-keepplaying playsInline id='videoplayer'>
                                                    <source src={`${item.liveMediaPath}`} type="video/mp4" />
                                                </video>                                       
                                            :
                                                <></>
                                            }
                                            { isDataCheck(item.pairingList) ?
                                                <div className={`better_menu better_menu${liveindex + 1}`}>
                                                    <button type="button" className="i_title" onClick={(e)=>pairingListClick(e, item.prdtId, liveindex + 1)}>함께하면 더 좋은 메뉴가 궁금하다면? 👍</button>
                                                    <div className='suggest_box'>
                                                        <Swiper
                                                            className="suggest_slider"
                                                            pagination= {{
                                                                el: '.i_pagination_dot', 
                                                            }}
                                                            observer
                                                            observeParents
                                                            slidesPerGroup={3}
                                                            slidesPerView="auto"
                                                            loop={false}>
                                                                { item.pairingList.map((pairingitem, pairIndex) => (
                                                                    <SwiperSlide>
                                                                        { (!isDataCheck(pairingitem.mediaPath)) ?
                                                                            <span className="food_thumb">
                                                                                { match.params.storeloc === 'in' &&
                                                                                    <div className="quantity_box_xs">
                                                                                        <div className="middle_inner">
                                                                                            <button type="button" className="btn_decrease" onClick={()=> PairDecreaseClick(pairingitem, pairIndex, liveindex)}><span className="blind">감소</span></button>
                                                                                            <span className={`i_num ${liveindex}_${pairIndex}_${pairingitem.pargId}`} count='0'>0</span>
                                                                                            <button type="button" className="btn_increase" onClick={()=> PairIncreaseClick(pairingitem, pairIndex, liveindex)}><span className="blind">증가</span></button>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                        
                                                                        :
                                                                            <span className="food_thumb" style={{ backgroundImage: `url('${pairingitem.mediaPath}')`}}>
                                                                                { match.params.storeloc === 'in' &&
                                                                                    <div className="quantity_box_xs">
                                                                                            <div className="middle_inner">
                                                                                                <button type="button" className="btn_decrease" onClick={()=>  PairDecreaseClick(pairingitem, pairIndex, liveindex)}><span className="blind">감소</span></button>
                                                                                                <span className={`i_num ${liveindex}_${pairIndex}_${pairingitem.pargId}`} count='0'>0</span>
                                                                                                <button type="button" className="btn_increase" onClick={()=> PairIncreaseClick(pairingitem, pairIndex, liveindex)}><span className="blind">증가</span></button>
                                                                                            </div>
                                                                                    </div>
                                                                                }
                                                                            </span>
                                                                        }
                                                                        <span className="food_name">{pairingitem.prdtNm}</span>
                                                                        <span className="food_price">{parseInt(pairingitem.price).toLocaleString()}원</span>
                                                                    </SwiperSlide>
                                                                ))
                                                                }
                                                                <div className="i_pagination_dot"></div>
                                                        </Swiper>
                                                    </div>
                                                </div>
                                            :
                                                <></>

                                            }
                                            <div className="food_desc">
                                                { item.tag !== null &&
                                                    item.tag === 'SIGNATURE' ?
                                                        <span className="i_tag signature"><span className="blind">시그니쳐</span></span>
                                                    : item.tag === 'HOT' ?
                                                        <span className="i_tag i_popular"><span className="blind">인기</span></span>
                                                    : item.tag === 'NEW' ?
                                                        <span className="i_tag i_new"><span className="blind">신제품</span></span>
                                                    : item.tag === 'REORDER' ?
                                                        <span className="i_tag i_reorder"><span className="blind">재주문 많음</span></span>
                                                    :
                                                        <></>
                                                }
                                                <span className="i_name">{item.prdtNm}</span>
                                                <span className="i_price">
                                                    <em>{parseInt(item.price).toLocaleString()}</em>
                                                    <span>원</span>
                                                </span>
                                                <p dangerouslySetInnerHTML={ {__html: item.prdtDesc} }></p>
                                                <div className="btn_wrap">
                                                    <button type="button" className={`btn_favorite favorite${liveindex + 1} usetap`} onClick={()=>MenuLikeClick(item.prdtId, liveindex + 1)}><span className="blind">좋아요</span></button>
                                                    { match.params.storeloc === 'in' &&
                                                        <button type="button" className="btn_basket usetap" onClick={() => SelectItem(item, 1)}><span className="blind">장바구니</span></button>
                                                    }
                                                </div>
                                            </div>
                                            <div className="top_gradient"></div>
                                            <div className="bottom_gradient"></div>
                                        </SwiperSlide>
                                    </>
                                ))
                            :
                                <></>
                            }
                        <div className="progressbar-wrap">
                            <div className="swiper-progress-bar">
                                <div className="progress"></div>
                                <div className="progress-sections"></div>
                            </div>
                        </div>
                    </Swiper>
                    <Route component={Foot} history={history} location={location} match={match} />
                </div>
            </div>

            {/* 옵션선택 팝업 */}
            <div className="modal fadeInUp modal_option_wrap" id="option_live_select_popup"> 
                <div className="dim bg_transparent" onClick={() => PopupClose()}></div>
                <div className="modal_inner modal_option">
                    <button type="button" className="pop_close" onClick={() => PopupClose()}><span className="blind">닫기</span></button>
                    <div className="modal_touch">
                        <div className="draggableItem">
                            <span className="food_name">{selectMenu?.prdtNm}</span>
                            <p className="food_desc" dangerouslySetInnerHTML={ { __html: selectMenu?.prdtDesc } }></p>
                        </div>
                        <div className="innerscroll">
                            { selectMenu !== null &&
                                <>
                                        <div className="option_wrap">
                                            <div className="opt_box opt_quantity">
                                                <span className="i_title">수량</span>
                                                <div className="quantity_box">
                                                    <button type="button" className="btn_decrease" onClick={() => DecreaseClick()}><span className="blind">감소</span></button>
                                                    <span className="i_num">{selectMenuCnt}</span>
                                                    { selectMenuCnt < 10 &&
                                                        <button type="button" className="btn_increase bg_red" onClick={() => IncreaseClick()}><span className="blind">증가</span></button>
                                                    }
                                                </div>
                                            </div>
                                            { selectMenu?.options !== null &&
                                                selectMenu.options?.options?.map((item, index) => (
                                                    item.optnGubun === 'S' ?
                                                        <div className="opt_box">
                                                            <span className="i_title">{item.optionName} 선택</span>
                                                            <ul className="opt_select">
                                                                {item.optionItems.map((optionitem, index) => (
                                                                    <li>
                                                                        <div className="radio_box">
                                                                            <input type="radio" name='optSelect' id={`optSelectSize${index + 1}`} className='radio_check' onClick={(e)=> OptionClick(e)} 
                                                                            value={optionitem.price} price={parseInt(optionitem.price)} optionName={item.optionName} 
                                                                            menu={optionitem.name} menuId={optionitem.id}/>
                                                                            <label htmlFor={`optSelectSize${index + 1}`}>
                                                                                <span className="opt_txt">{optionitem.name}</span>
                                                                                <span className="opt_price">{optionitem.price === '0' ? '추가금액없음' : `${parseInt(optionitem.price).toLocaleString()}원`}</span>
                                                                            </label>
                                                                        </div>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                :
                                                    <div className="opt_box">
                                                        <span className="i_title">{item.optionName} 선택</span>
                                                        <ul className="opt_select">
                                                            {item.optionItems.map((optionitem, index) => (
                                                                <li>
                                                                    <div className="chk_box">
                                                                        <input type="checkbox" name='optSelect' id={`optAddFood1${index + 1}`}  className='chk'
                                                                        onClick={(e)=> OptionClick(e)} value={parseInt(optionitem.price)} 
                                                                        price={optionitem.price} menu={optionitem.name} menuId={optionitem.id} optionName={item.optionName}/>
                                                                        <label htmlFor={`optAddFood1${index + 1}`}>
                                                                            <span className="opt_txt">{optionitem.name}</span>
                                                                            <span className="opt_price">{optionitem.price === '0' ? '추가금액없음' : `${parseInt(optionitem.price).toLocaleString()}원`}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                </>
                            }
                        </div>
                        <div className="sum_fixed">
                            <dl className="sum_total">
                                <dt>총 주문금액</dt>
                                <dd><em>{totalPrice.toLocaleString()}</em>원</dd>
                            </dl>
                            <button type="button" className="btn_active btn_point" onClick={() =>BasketClickHandler()}><span className="total_num">{selectMenuCnt}</span>장바구니에 담기</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
});

export default Live;