import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import $ from 'jquery'
import { layerPopup, toast } from '../../../libs/common';
import SohoApi from '../../../api/SohoApi';
import { Swiper, SwiperSlide } from 'swiper/react'
import { isNullData, menuBasketPush, numberWithCommas, PairingMenuBasketPush } from '../../../common/common';
import userData from '../../../store/userData';
import { setScreenSize, touchUi } from '../../../libs/touchUi';
import * as CONSTS from '../../../const/index'


const MoveMenu = observer(({storeloc ,menuList, storeId, history}) => {

    const [oncePrdList, setOncePrdList] = useState([]);
    const [onceMenu, setOnceMenu] = useState([]);
    const [fairingMenu, setFairingMenu] = useState([]);
    const [optionData, setOptionData] = useState(null);
    const [count, setCount] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0)
    const [selecList, setSelecList] = useState([]);

    const [id, setId] = useState("");
    
    // 페어링 리스트 호출필요 없이 props로 받아온 데이터에서 출력가능

    useEffect(() => {  
    },[oncePrdList])

    useEffect(() => {
        if(optionData !== null){
            totalOrderPrice();
        }
    },[count,selecList,optionData,onceMenu])

    useEffect(() => {
        if(optionData !== null) defaultCheck()
    },[optionData])

    useEffect(() => {
        if(storeloc){
            onceProductList();
        }
    },[storeloc])

    useEffect(() => {
        setOptionData({
            id: onceMenu.prdtId,
            name : onceMenu.prdtNm,
            price : onceMenu.price ,
            desc : onceMenu.desc,
            options : onceMenu.options,
            imgPath : onceMenu.mediaPath
        })
    },[onceMenu])

    useEffect(() => {
        if(userData.dataset.drgPopupHideFlag){
            checkBoxRelease();
            setTotalPrice(0);
            setSelecList([]);
            setCount(1)
            userData.setDrgPopupHideFlag(false);
        }
    },[userData.dataset.drgPopupHideFlag])

    const totalOrderPrice = () => {
         
            //사용자가 선택한 옵션
            const individualPrice = selecList?.map((items) => {
                return items.price;
            })
            //선택한 옵션 가격 더하기
            const totalIndividualPrice = individualPrice?.reduce((acc, cur) => {
                return Number(acc)+ Number(cur);
            },0)
            //(옵션 가격 + 음식가격) * 갯수
            const totalPrice = count * (Number(onceMenu.price) + totalIndividualPrice);
            console.log("totalPrice",totalPrice);
            setTotalPrice(totalPrice)
       
    }

    const optionsPopup = (e) => {
        setOptionData({
            id: onceMenu.id,
            name : onceMenu.prdtNm,
            price : onceMenu.price ,
            desc : onceMenu.desc,
            options : onceMenu.options,
            imgPath : onceMenu.mediaPath
        })
        layerPopup.show("#option_menu_select2_popup");

        e.preventDefault();
		e.stopPropagation();
    }
    
    const imgMapClick = async (id, e) => {
        
        const prdtId = e.currentTarget.getAttribute("prdtId");
        $(`input[type='radio'][id='${id}']`).prop('checked', true);
        setId(prdtId);
        //TODO promise all
        await onceMenuSearch(prdtId);
        await fetchPairingList(prdtId);
        if(storeloc === "in"){
            document.querySelector('.innerscroll').style.height = 'auto';
            layerPopup.show("#move_menu_basket_popup");
        }else{
            document.querySelector('.innerscroll').style.height = 'auto';
            layerPopup.show("#menu_select_popup");
        }
        // layerPopup.show("#menu_select_popup");
    }
    // const imgMapClick = async (id, onceId) => {
    //     // touchUi.init(document.getElementById('move_menu_basket_popup'));
        
    //     $(`input[type='radio'][id='${id}']`).prop('checked', true);
    //     setId(onceId);
    //     //TODO promise all
    //     await onceMenuSearch(onceId);
    //     // await optionsPopup();
    //     await fetchPairingList(onceId);
    //     if(storeloc === "in"){
    //         document.querySelector('.innerscroll').style.height = 'auto'
    //         layerPopup.show("#move_menu_basket_popup");
    //     }else{
    //         layerPopup.show("#menu_select_popup");
    //     }
    // }
    useEffect(() => {
        if(storeloc === "in"){
            touchUi.init(document.getElementById('move_menu_basket_popup'),"#move_menu_basket_popup");
        }else {
            touchUi.init(document.getElementById('menu_select_popup'), "#menu_select_popup");
        }
    },[id])

    const onceMenuSearch = (id) => {
        const oncePrdtList = menuList.categoryList.map((items,idx) => {
            return items.productList;
        })
        //상품id 같은거 찾아오기
        const oncePrdt = oncePrdtList.map((items, idx) => {
            const search = items.filter((item) => {
                if(item.prdtId === id){
                    setOnceMenu(item)
                    return item
                }
            })
            return items
        })
    }

     //좋아요
     const fetchLike = async() => {
        if($('.btn_favorite').hasClass('on')) {
           $('.btn_favorite').removeClass('on')
       } else {
               try {
                  const likeApi = await SohoApi.getAddInflowHistory({
                       storeId: storeId, //TODO : 값 최신화
                       histType: 'L',
                       flowType: '',
                       flowPath: id                    //    flowPath: match.params.prdtId;
                   })
                   if(likeApi.retCode === "0000"){
                       toast("좋아요");
                       $('.btn_favorite').toggleClass('on');
                   }
               } catch(e) {
                   console.log("errer",e);
               }
       }
   }

    const onceProductList = async () => {
        try {
            await SohoApi.getOnceProductList({
                storeId : storeId
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    console.log("result", rst);
                    setOncePrdList(rst.data);
                }
            }).catch((e) => {
                console.log(e);
                toast('한눈에 보기 메뉴 정보를 가져오는데 실패했습니다.');
            })
        } catch (e) {
            console.log(e);
            toast('한눈에 보기 메뉴 정보를 가져오는데 실패했습니다.');
        }

    }

    //페어링 리스트
    const fetchPairingList = async(id) => {
        try {
            const pairingList = await SohoApi.getPairingList({
                prdtId: id //상품 id
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    setFairingMenu(rst.data);
                    console.log("pairing", rst.data);
                }
            }).catch((e) => {
                toast('좋아요 처리에 실패 했습니다.');
            })

        } catch(e) {
            console.log(e);
            toast('좋아요 처리에 실패 했습니다.');
        }
    }

    const checkBoxRelease = () => {
        const checkBox = document.getElementsByName("selectMenu");
        Array.from(checkBox).forEach((items) => {
           items.checked = false;
        })
    }

    const dataReset = () => {
        checkBoxRelease();
        //필요성확인
        setScreenSize();
        if(storeloc === "in"){
            touchUi.init(document.getElementById('move_menu_basket_popup'),"#move_menu_basket_popup");
        }else {
            touchUi.init(document.getElementById('option_menu_select2_popup'),"#option_menu_select2_popup");
        }
        setTotalPrice(0);
        setCount(1);
        setSelecList([]);
        // setOptionData(null);
        if(storeloc === "in"){
            layerPopup.hide("#move_menu_basket_popup");
        }else{
            layerPopup.hide("#option_menu_select2_popup");
        }
    }
         //수량 플러스
    const plusCount = () => {
        if(count === 10) return;
        setCount((prev) =>  prev + 1);
    }
    //수량 마이너스
    const minusCount = () => {
        if(count === 1) return;
        setCount((prev) => prev - 1);
    }

    const defaultCheck = () => {
        //옵션에서 단일 선택만 불러오기
        if(optionData.options === null) return;
        const searchOption = optionData?.options?.options?.map((items) => { 
            if(items.optnGubun === "S"){
                console.log("items.optionType", items.optionType)
                return items.optionType;
            }
        })
        //단일선택 옵션이 있을경우에만 로직 진행
        if(searchOption?.length > 0){
            searchOption.forEach((items, idx) => {
                if(items !== undefined){

                    //해당 각 id의 첫번째 항목만 checked = true
                    const firstItem = document.getElementById(items + "0");
                    console.log("firstItem",firstItem);
                    firstItem.checked = true;

                    //selecList에 담기
                    const obj = {
                        "id" : firstItem.getAttribute("menuId"),
                        "optionName" : firstItem.name,
                        "price" : firstItem.getAttribute("price"),
                        "name" :  firstItem.getAttribute("menu"),
                        "optionDesc" : (firstItem.name === '사이즈' ? `${firstItem.name} ` : '') +
                                        firstItem.getAttribute("menu") + 
                                        (firstItem.getAttribute("price") === '0' ? "(추가금액 없음)" : ' (+' + parseInt(firstItem.getAttribute("price")).toLocaleString() + '원)')
                    }
                    setSelecList(oldArray => [...oldArray, obj ])
                }
            })
        }

    }

    const inputClickRadioBox = (e) => {
        //총 주문금액을 책정하기 위해 가격과 메뉴이름을 가져온다
        const obj = {
            "id" : e.currentTarget.getAttribute("menuId"),
            "optionName" : e.currentTarget.name,
            "name" :  e.currentTarget.getAttribute("menu"),
            "price" : e.currentTarget.getAttribute("price"),
            "optionDesc" : (e.currentTarget.name === '사이즈' ? `${e.currentTarget.name} ` : '') + 
                            e.currentTarget.getAttribute("menu") + 
                            (e.currentTarget.getAttribute("price") === "0" ? "(추가금액 없음)" : ' (+' + parseInt(e.currentTarget.getAttribute("price")).toLocaleString()+ '원)')
        }
        console.log("obj",obj);
        // 동일 카테고리 내에서 배열에 하나씩만 담기도록 함
        const find = selecList?.find((item) => {
            if(item.optionName === e.currentTarget.name){
                return item;
            }
        })

        if(find !== undefined){
            setSelecList(selecList?.filter((items) => items.optionName !== e.currentTarget.name))
        }
        setSelecList(oldArray => [...oldArray, obj ]);
    }

    //체크박스 클릭
    const inputClickCheckBox = (e) => {
        const obj = {
            "id" : e.currentTarget.getAttribute("menuId"),
            "optionName" : e.currentTarget.name,
            "name" :  e.currentTarget.getAttribute("menu"),
            "price" : e.currentTarget.getAttribute("price"),
            "optionDesc" : (e.currentTarget.name === '사이즈' ? `${e.currentTarget.name} ` : '') +
                            e.currentTarget.getAttribute("menu") + 
                            (e.currentTarget.getAttribute("price") === "0" ? "(추가금액 없음)" : ' (+' + parseInt(e.currentTarget.getAttribute("price")).toLocaleString() + '원)')
            
        }
        //체크해제 일경우 배열에서 빼기
        if(e.target.checked === false){
            setSelecList(selecList?.filter((items) => items.name !== e.currentTarget.getAttribute("menu")))
            return;
        }

        setSelecList(oldArray => [...oldArray, obj ]);
    }

    const basketPut = async (e) => {
        const options = await optionCheck();
        //menu.js에서 상품리스트 중 장바구니 클릭하면 페이지 넘어가는 현상 막기위함
        e.preventDefault();
		e.stopPropagation();
        console.log("optionData.id",optionData);
        //store에 넣을 데이터를 가져온다
        const menu = {
            prdtId : optionData.id,
            tag : "",
            prdtNm : optionData.name,
            prdtDesc : optionData.desc,
            allergy : "",
            ingredients: "",
            prdtInfo: "",
            eatingTipsL: "",
            prdtImg : optionData.imgPath, //imgPath
            // vodPath : foodDetail.vodPath,
            // useType : foodDetail.useType,
            price : totalPrice / count, // 제품가격
            totalPrice : totalPrice, // 총가격
            prdtCnt : count, // 음식갯수
            // options : selecList.length > 0 ? selecList : []
            options : options
        };

        //장바구니 담기 
        await menuBasketPush(menu, count);
        dataReset();
        //공통으로 쓰일지 몰라 따로 뺌
        // menuBasketPush(menu);
    }

    //장바구니 담기 클릭 했을 때 
    const optionCheck = () => {
        let options = [];
        const radioCheck = document.getElementsByClassName("radio_check");
        const check = document.getElementsByClassName("chk");

        Array.from(radioCheck).forEach((items,idx) =>{
            if(items.checked === true){
               const obj = {
                   "id" : items.getAttribute("menuId"),
                   "optionName" : items.name,
                   "price" : items.getAttribute("price"),
                   "name" : items.getAttribute("menu"),
                   "optionDesc" : (`${(items.name === '업그레이드' ? '' : items.name)} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                   parseInt(items.getAttribute("price")).toLocaleString() + '원)'))
               }
               options.push(obj);
            }
        })

        Array.from(check).forEach((items,idx) => {
            if(items.checked === true){
                const obj = {
                    "id" : items.getAttribute("menuId"),
                    "optionName" : items.name,
                    "price" : items.getAttribute("price"),
                    "name" : items.getAttribute("menu"),
                    "optionDesc" : (`${(items.name === '업그레이드' ? '' : items.name)} ` + items.getAttribute("menu") + (items.getAttribute("price") === '0' ? "(추가금액 없음)" : '(+' + 
                                    parseInt(items.getAttribute("price")).toLocaleString() + '원)'))
                }
                options.push(obj);
            }
        })
        return options;
    }

    const PairDecreaseClick = (pairItem, index) => {
        let currItem = $(`.${index}_${pairItem.pargId}`);   
        let currText = currItem.text();


        if(parseInt(currText) > 0) {
            currItem.text(parseInt(currText) - 1);
            
            console.log(currText);

            const selectPrice = userData.dataset.basketList.map((product) => {
                if(pairItem.pargId === product.prdtId) {
                    return { ...product, prdtCnt: product.prdtCnt - 1, totalPrice: product.price * (product.prdtCnt-1) }
                } else {
                    return product;
                }
            });
            userData.setBasketPut(selectPrice);
        } 
        
        if(parseInt(currText) === 1) {        
            const deleteProduct = userData.dataset.basketList.filter((item) => {
                if(item.prdtCnt !== 0) {
                    return item;
                }
            });
            userData.setBasketPut(deleteProduct);
            toast('선택하신 메뉴가 삭제되었습니다.');
        }
    }

    const PairIncreaseClick = (pairItem, index) => {
        let currItem = $(`.${index}_${pairItem.pargId}`);   
        let currText = currItem.text();
        console.log(`${index}_${pairItem.pargId}`);
        console.log(currItem);
        console.log(currText);

        if(parseInt(currText) < 10) {
            currItem.text(parseInt(currText) + 1);

            const menu = {
                prdtId : pairItem.pargId,
                prdtNm : pairItem.prdtNm,
                prdtImg : pairItem.mediaPath,
                price : parseInt(pairItem.price), // 제품가격
                totalPrice : parseInt(pairItem.price) * parseInt(currItem.text()),  // 총가격
                prdtCnt : parseInt(currItem.text()), // 음식갯수
                options : []
            }
            PairingMenuBasketPush(menu);
        }
    }

    const menuPopupHide = () => {
        checkBoxRelease();
        layerPopup.hide("#menu_select_popup");   
        $('.btn_favorite').removeClass('on');
    }

    return (
        <>
            <div className="swipe_wrap">
                <div className="swipe_img">
                {/* <img src={`${oncePrdList.onceImg}`} alt="" useMap="#image-map" /> */}
                    <img src={`${oncePrdList?.onceImg}`} border="0" alt="" useMap="#image-map" />
                    <map id="map_ID" name="image-map">
                        {oncePrdList?.onceProductList?.map((items, idx) => (
                             !isNullData(items.coordinate) &&
                             <area coords={items.coordinate.coords} shape="0" prdtId={items.prdtId}  onClick={(e) => imgMapClick(`selectMenu${idx + 1}`, e)} />
                        ))}
                        {/* <area coords="0,204,177,374" shape="0" onClick={() => imgMapClick('selectMenu1', "220325SSGPRDT000028")} />
                        <area coords="1,414,200,616" shape="0" onClick={() => imgMapClick('selectMenu2', "220325SSGPRDT000033")} />
                        <area coords="216,56,405,247" shape="0" onClick={() => imgMapClick('selectMenu3', "220325SSGPRDT000015")} />
                        <area coords="206,265,413,468" shape="0" onClick={() => imgMapClick('selectMenu4', "220325SSGPRDT000017")} />
                        <area coords="238,504,355,614" shape="0" onClick={() => imgMapClick('selectMenu5', "220325SSGPRDT000061")} />
                        <area coords="180,625,275,717" shape="0" onClick={() => imgMapClick('selectMenu6', "220325SSGPRDT000053")} />
                        <area coords="421,2,641,153" shape="0" onClick={() => imgMapClick('selectMenu7', "220325SSGPRDT000060")} />
                        <area coords="456,188,840,562" shape="0" onClick={() => imgMapClick('selectMenu8', "220325SSGPRDT000021")} />
                        <area coords="658,1,866,171" shape="0" onClick={() => imgMapClick('selectMenu9', "220325SSGPRDT000030")} />
                        <area coords="891,0,1054,379" shape="0" onClick={() => imgMapClick('selectMenu10', "220325SSGPRDT000024")} />
                        <area coords="868,419,1062,611" shape="0"  onClick={() => imgMapClick('selectMenu11', "220325SSGPRDT000008")} />
                        <area coords="1075,31,1271,241" shape="0" onClick={() => imgMapClick('selectMenu12', "220325SSGPRDT000014")} />
                        <area coords="1085,256,1272,520" shape="0" onClick={() => imgMapClick('selectMenu13', "220325SSGPRDT000056")} /> */}
                    </map>
                    {
                        oncePrdList?.onceProductList?.map((items, idx) => (
                            !isNullData(items.coordinate) &&
                            <div className={Number(items.coordinate.style?.bottom.replace('%','')) >= 80 ? "radio_wrap reverse" : "radio_wrap"}
                            // 퍼블임시 적용 논의사항 필요 //상단과 가까운 경우 reverse class 추가
                                style={{
                                    bottom : items.coordinate.style?.bottom,
                                    left : items.coordinate.style?.left
                                }}
                            >
                                <input type="radio" id={`selectMenu${idx + 1}`}  prdtId={items.prdtId} name="selectMenu" onClick={(e) => imgMapClick(`selectMenu${idx + 1}`, e)} />
                                <label htmlFor={`selectMenu${idx + 1}`}>
                                        <span className="bubble_box">
                                            <span className="food_name">{items.prdtNm}</span>
                                            <span className="food_price">{numberWithCommas(items.price)}원</span>
                                        </span>
                                        <div className="circle_wrap">
                                            <span className="ico_circle"></span>
                                        </div>
                                </label>
                            </div>
                        ))
                    }
                    {/* <div className="radio_wrap" style={{bottom: '61%', left: '5.5%'}}>
                        <input 
                            type="radio" 
                            id="selectMenu1" 
                            name="selectMenu"
                            prdtId="220325SSGPRDT000028"
                            onClick={() => imgMapClick('selectMenu1', "220325SSGPRDT000028")} 
                        />
                        <label htmlFor="selectMenu1">
                                <span className="bubble_box">
                                    <span className="food_name">다마코카츠 4pc</span>
                                    <span className="food_price">5,900원</span>
                                </span>
                                <div className="circle_wrap">
                                    <span className="ico_circle"></span>
                                </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '29%', left: '3.5%'}}>
                        <input type="radio" id="selectMenu2" name="selectMenu" prdtId="220325SSGPRDT000033" onClick={() => imgMapClick('selectMenu2', "220325SSGPRDT000033")} />
                        <label htmlFor="selectMenu2">
                            <span className="bubble_box">
                                <span className="food_name">듬뿍마제소바</span>
                                <span className="food_price">14,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '76%', left: '18%'}}>
                        <input type="radio" id="selectMenu3" name="selectMenu" prdtId="220325SSGPRDT000015" onClick={() => imgMapClick('selectMenu3', "220325SSGPRDT000015")} />
                        <label htmlFor="selectMenu3">
                            <span className="bubble_box">
                                <span className="food_name">니마생마구로동</span>
                                <span className="food_price">31,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap"  style={{bottom: '44%', left: '25.5%'}}>
                        <input type="radio" id="selectMenu4" name="selectMenu" prdtId="220325SSGPRDT000017" onClick={() => imgMapClick('selectMenu4', "220325SSGPRDT000017")} />
                        <label htmlFor="selectMenu4">
                            <span className="bubble_box">
                                <span className="food_name">히츠마부시</span>
                                <span className="food_price">38,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '21%', left: '22%'}}>
                        <input type="radio" id="selectMenu5" name="selectMenu" prdtId="220325SSGPRDT000061" onClick={() => imgMapClick('selectMenu5', "220325SSGPRDT000061")} />
                        <label htmlFor="selectMenu5">
                            <span className="bubble_box">
                                <span className="food_name">준마이 750</span>
                                <span className="food_price">37,000원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '7%', left: '13%'}}>
                        <input type="radio" id="selectMenu6" name="selectMenu" prdtId="220325SSGPRDT000053" onClick={() => imgMapClick('selectMenu6', "220325SSGPRDT000053")}/>
                        <label htmlFor="selectMenu6">
                            <span className="bubble_box">
                                <span className="food_name">히레사케</span>
                                <span className="food_price">9,000원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '80%', left: '34.5%'}}>
                        <input type="radio" id="selectMenu7" name="selectMenu" prdtId="220325SSGPRDT000060" onClick={() => imgMapClick('selectMenu7', "220325SSGPRDT000060")} />
                        <label htmlFor="selectMenu7">
                            <span className="bubble_box">
                                <span className="food_name">월계관 준마이 다이긴조</span>
                                <span className="food_price">37,000원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '33%', left: '44.5%'}}>
                        <input type="radio" id="selectMenu8" name="selectMenu" prdtId="220325SSGPRDT000021" onClick={() => imgMapClick('selectMenu8',"220325SSGPRDT000021")} />
                        <label htmlFor="selectMenu8">
                            <span className="bubble_box">
                                <span className="food_name">혼마구로별미사시미</span>
                                <span className="food_price">72,000원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '85%', left: '56%'}}>
                        <input type="radio" id="selectMenu9" name="selectMenu" prdtId="220325SSGPRDT000030" onClick={() => imgMapClick('selectMenu9','220325SSGPRDT000030')} />
                        <label htmlFor="selectMenu9">
                            <span className="bubble_box">
                                <span className="food_name">왕새우튀김 2pc</span>
                                <span className="food_price">9,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '57%', left: '70%'}}>
                        <input type="radio" id="selectMenu10" name="selectMenu" prdtId="220325SSGPRDT000024" onClick={() => imgMapClick('selectMenu10', '220325SSGPRDT000024')} />
                        <label htmlFor="selectMenu10">
                            <span className="bubble_box">
                                <span className="food_name">그랜드 후토마끼 4pc</span>
                                <span className="food_price">17,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '23%', left: '77%'}}>
                        <input type="radio" id="selectMenu11" name="selectMenu" prdtId="220325SSGPRDT000008" onClick={() => imgMapClick('selectMenu11','220325SSGPRDT000008')} />
                        <label htmlFor="selectMenu11">
                            <span className="bubble_box">
                                <span className="food_name">특품 카미동</span>
                                <span className="food_price">23,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '76%', left: '84%'}}>
                        <input type="radio" id="selectMenu12" name="selectMenu" prdtId="220325SSGPRDT000014" onClick={() => imgMapClick('selectMenu12', '220325SSGPRDT000014')} />
                        <label htmlFor="selectMenu12">
                            <span className="bubble_box">
                                <span className="food_name">화르륵 아부리동</span>
                                <span className="food_price">21,900원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div>
                    <div className="radio_wrap" style={{bottom: '46%', left: '90%'}}>
                        <input type="radio" id="selectMenu13" name="selectMenu" prdtId="220325SSGPRDT000056" onClick={() => imgMapClick('selectMenu13','220325SSGPRDT000056')} />
                        <label htmlFor  ="selectMenu13">
                            <span className="bubble_box">
                                <span className="food_name">센</span>
                                <span className="food_price">7,000원</span>
                            </span>
                            <div className="circle_wrap">
                                <span className="ico_circle"></span>
                            </div>
                        </label>
                    </div> */}
                </div>
            </div> 

            {storeloc === "out" ? 
            <>
            <div className="modal fadeInUp" id="menu_select_popup"> 
                <div className="dim bg_transparent"  onClick={menuPopupHide}></div>
                <div className="modal_inner modal_menu">
                    <div className="modal_touch">
                        <div className="draggableItem">
                            <span className="food_name">{onceMenu.prdtNm}</span>
                            <span className="food_price">{numberWithCommas(onceMenu.price)}원</span>
                                <p className="food_desc">{onceMenu.desc}</p>
                            <div className="btn_wrap">
                                <button type="button" className="btn_favorite usetap" onClick={() => fetchLike()} ><span className="blind">좋아요</span></button>
                                {/* <button 
                                    type="button" 
                                    className="btn_basket usetap" 
                                    onClick={(e) => optionsPopup(e)}
                                >
                                    <span className="blind">장바구니</span>
                                </button> */}
                            </div>
                        </div>
                        <div className="innerscroll">
                        {fairingMenu?.pairingList?.length > 0 &&
                            <div className="suggest_box">
                                <span className="i_title"><span className="pt_color">‘{onceMenu.prdtNm}’</span> 와 함께 하면 더 맛있어요!</span>
                                <Swiper 
                                        className='suggest_slider'
                                        observeParents={true}
                                        observer={true}
                                        pagination={{ 
                                            clickable: true, 
                                            el: '.i_pagination_dot', 
                                        }}
                                        slidesPerView="auto"
                                        slidesPerGroup={3}
                                        loop={false}
                                >
                                    <div className="swiper-wrapper">
                                        {
                                            fairingMenu?.pairingList?.map((items, idx) => (
                                                    <SwiperSlide key={idx}>
                                                        <span className="food_thumb" style={{backgroundImage : `url('${items.mediaPath}')`}}>
                                                            {/* <div className="quantity_box_xs">
                                                                <div className="middle_inner">
                                                                    <button type="button" id={items.pargId}  className="btn_decrease" onClick={()=> PairDecreaseClick(items, idx)}><span className="blind">감소</span></button>
                                                                    <span className={`i_num ${idx}_${items.pargId}`}>0</span>
                                                                    <button type="button" 
                                                                        id={items.pargId} 
                                                                        name={items.prdtNm}
                                                                        price={items.price}
                                                                        className="btn_increase"
                                                                        onClick={()=> PairIncreaseClick(items, idx)}
                                                                    >
                                                                        <span className="blind">증가</span>
                                                                    </button>
                                                                </div>
                                                            </div> */}
                                                        </span>
                                                        
                                                        <span className="food_name">{items.prdtNm}</span>
                                                        <span className="food_price">{numberWithCommas(items.price) + '원'}</span>
                                                    </SwiperSlide>
                                            ))
                                        }
                                            
                                        </div>
                                        <div className="i_pagination_dot"></div>

                                </Swiper>
                                
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
                        
             <div className="modal fadeInUp modal_option_wrap" id="option_menu_select2_popup"> 
                <div className="dim bg_transparent" onClick={() => dataReset()}></div>
                <div className="modal_inner modal_option">
                    <button type="button" className="pop_close" onClick={() => dataReset()}><span className="blind">닫기</span></button>
                    <div className="modal_touch">
                        <div className="draggableItem">
                            <span className="food_name">{optionData?.name}</span>
                            <p className="food_desc">{optionData?.desc}</p>
                        </div>
                        <div className="innerscroll">
                            <div className="option_wrap">
                                <div className="opt_box opt_quantity">
                                    <span className="i_title">수량</span>
                                    <div className="quantity_box">
                                        <button type="button" className="btn_decrease" onClick={minusCount}><span className="blind">감소</span></button>
                                        <span className="i_num">{count}</span>
                                        <button type="button" className="btn_increase bg_red" onClick={plusCount}><span className="blind">증가</span></button>
                                    </div>
                                </div>
                                {optionData?.options !== null &&
                                        optionData?.options?.options?.map((items, idx) => (
                                            items.optnGubun === 'S' ?
                                                <div className="opt_box">
                                                    <span className="i_title">{items.optionName}</span>
                                                    <ul className="opt_select">
                                                        {items.optionItems.map((item, index) => (
                                                            <>
                                                                <li key={item.id}>
                                                                    <div className="radio_box">
                                                                        <input className="radio_check"type="radio" onClick={(e) => inputClickRadioBox(e)} id={`${items.optionType}${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                        <label htmlFor={`${items.optionType}${index}`}>
                                                                            <span className="opt_txt">{item.name}</span>
                                                                            <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))}
                                                    </ul>
                                                </div>
                                            : items.optnGubun === 'M' ?
                                                <div className="opt_box">
                                                    <span className="i_title">{items.optionName}</span>
                                                    <ul className="opt_select">
                                                        {items.optionItems.map((item, index) => (
                                                            <>
                                                                <li key={item.id}>
                                                                    <div className="chk_box">
                                                                        <input className="chk"type="checkbox" onClick={(e) => inputClickCheckBox(e)} id={`optAddFood${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                        <label htmlFor={`optAddFood${index}`}>
                                                                            <span className="opt_txt">{item.name}</span>
                                                                            <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                        </label>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ))}
                                                    </ul>
                                                </div>
                                            : <></>
                                        ))}
                            </div>
                        </div>
                                
                        {/* <!-- 총 주문금액 --> */}
                        <div className="sum_fixed">
                            <dl className="sum_total">
                                <dt>총 주문금액</dt>
                                <dd><em>{numberWithCommas(totalPrice)}</em>원</dd>
                            </dl>
                            <button type="button" onClick={basketPut} className="btn_active btn_point"><span className="total_num">{count + '+'}</span>장바구니에 담기</button>
                        </div>
                    </div>
                </div>
            </div>
            </>
            :
            <>
            <div className="modal fadeInUp modal_option_wrap" id="move_menu_basket_popup"> 
                <div className="dim bg_transparent" onClick={dataReset}></div>
                <div className="modal_inner modal_option">
                    <button type="button" className="pop_close" onClick={dataReset}><span className="blind">닫기</span></button>
                    <div className="modal_touch">
                        <div className="draggableItem">
                            <span className="food_name">{onceMenu.prdtNm}</span>
                            <span className="food_price">{numberWithCommas(onceMenu.price)}원</span>
                            <p className="food_desc">{onceMenu.desc}</p>
                        </div>
                        <div className="innerscroll">
                            <div className="option_wrap">
                                <div className="opt_box opt_quantity">
                                    <span className="i_title">수량</span>
                                    <div className="quantity_box">
                                        <button type="button" className="btn_decrease" onClick={minusCount}><span className="blind">감소</span></button>
                                        <span className="i_num">{count}</span>
                                        <button type="button" className="btn_increase bg_red" onClick={plusCount}><span className="blind">증가</span></button>
                                    </div>
                                </div>
                                
                                {optionData?.options !== null &&
                                                    optionData?.options?.options?.map((items, idx) => (
                                                        items.optnGubun === 'S' ?
                                                            <div className="opt_box">
                                                                <span className="i_title">{items.optionName}</span>
                                                                <ul className="opt_select">
                                                                    {items.optionItems.map((item, index) => (
                                                                        <>
                                                                            <li key={item.id}>
                                                                                <div className="radio_box">
                                                                                    <input className="radio_check"type="radio" onClick={(e) => inputClickRadioBox(e)} id={`${items.optionType}${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                                    <label htmlFor={`${items.optionType}${index}`}>
                                                                                        <span className="opt_txt">{item.name}</span>
                                                                                        <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        : items.optnGubun === 'M' ?
                                                            <div className="opt_box">
                                                                <span className="i_title">{items.optionName}</span>
                                                                <ul className="opt_select">
                                                                    {items.optionItems.map((item, index) => (
                                                                        <>
                                                                            <li key={item.id}>
                                                                                <div className="chk_box">
                                                                                    <input className="chk"type="checkbox" onClick={(e) => inputClickCheckBox(e)} id={`optAddFood${index}`} name={items.optionName}  price={item.price} menu={item.name} menuId={item.id} />
                                                                                    <label htmlFor={`optAddFood${index}`}>
                                                                                        <span className="opt_txt">{item.name}</span>
                                                                                        <span className="opt_price">{item.price !== '0' ? '+' + numberWithCommas(item.price) + '원' : '추가금액 없음'}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </li>
                                                                        </>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        : <></>
                                                    ))}

                            </div>
                            {fairingMenu?.pairingList?.length > 0 &&
                            <div className="suggest_box">
                                <span className="i_title"><span className="pt_color">‘{onceMenu.prdtNm}’</span> 와 함께 하면 더 맛있어요!</span>
                                <Swiper 
                                                    className='suggest_slider'
                                                    observeParents={true}
                                                    observer={true}
                                                    pagination={{ 
                                                        clickable: true, 
                                                        el: '.i_pagination_dot', 
                                                    }}
                                                    slidesPerView="auto"
                                                    slidesPerGroup={3}
                                                    loop={false}
                                            >
                                                <div className="swiper-wrapper">
                                                    {
                                                        fairingMenu?.pairingList?.map((items, idx) => (
                                                                <SwiperSlide key={idx}>
                                                                    <span className="food_thumb" style={{backgroundImage : `url('${items.mediaPath}')`}}>
                                                                        <div className="quantity_box_xs">
                                                                            <div className="middle_inner">
                                                                                <button type="button" id={items.pargId}  className="btn_decrease" onClick={()=> PairDecreaseClick(items, idx)}><span className="blind">감소</span></button>
                                                                                <span className={`i_num ${idx}_${items.pargId}`}>0</span>
                                                                                <button type="button" 
                                                                                    id={items.pargId} 
                                                                                    name={items.prdtNm}
                                                                                    price={items.price}
                                                                                    className="btn_increase"
                                                                                    onClick={()=> PairIncreaseClick(items, idx)}
                                                                                >
                                                                                    <span className="blind">증가</span>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </span>
                                                                    
                                                                    <span className="food_name">{items.prdtNm}</span>
                                                                    <span className="food_price">{numberWithCommas(items.price) + '원'}</span>
                                                                </SwiperSlide>
                                                        ))
                                                    }
                                                    </div>
                                                    <div className="i_pagination_dot"></div>

                                            </Swiper>
                            </div>
                            }
                        </div   >
                        <div className="sum_fixed">
                            <dl className="sum_total">
                                <dt>총 주문금액</dt>
                                <dd><em>{numberWithCommas(totalPrice)}</em>원</dd>
                            </dl>
                            <button type="button" onClick={basketPut} className="btn_active btn_point"><span className="total_num">{count + '+'}</span>장바구니에 담기</button>
                        </div>
                    </div>
                </div>
            </div>
</>
}


            

        </>
    )
});

export default MoveMenu