import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import { useObserver } from 'mobx-react';
import Main from './Main';

function App() {
  return (
    <Router>
      <Route component={Main} />
    </Router>
  );
}

export default App;