import React from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import { isDataCheck } from '../../common/common';
import { toast } from '../../libs/common';

const Foot = observer(({ location, history, match }) => {

    const { userData } = store;

    const goMainPage = (page) => {
        if(page === 'basket' && !isDataCheck(userData.dataset.basketList)) {
            toast('메뉴를 추가해주세요.');
            return;
        }
        userData.setMenuTabList("fullmenu");
        history.push(`/${page}/${match.params.storeloc}/${match.params.storeId}?tblNum=${userData.dataset.tblNum}`);
    }

    return (
        <>
            <div className="live_gnb">
                <button type="button" className={"gnb_live" + (location.pathname.indexOf('/live') > -1 ? " on" : "")} onClick={()=>goMainPage('live')}>Live 메뉴</button>
                { match.params.storeloc === 'in' &&
                    <button type="button" className={"gnb_basket" + (location.pathname.indexOf('/basket') > -1 ? " on" : "")} onClick={()=>goMainPage('basket')}>
                        <span className="blind">장바구니</span>
                        { isDataCheck(userData.dataset.basketList) ?
                            <span className="i_num">{userData.dataset.basketList.map((item) => (item.prdtCnt)).reduce((a, b) => a + b, 0)}</span>
                        :
                            <></>
                        }
                    </button>
                }
                <button type="button" className={"gnb_menuAll" + (location.pathname.indexOf('/menu') > -1 ? " on" : "")} onClick={()=>goMainPage('menu')}>전체 메뉴판</button>
            </div>
        </>
    )
});

export default Foot;