import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import store from '../../store';
import $ from 'jquery'
import { toast } from '../../libs/common';

const Home = observer(({ history }) => {
    const { userData } = store;
    const [storeId, setStoreId] = useState('220413USSTIN000739');
    const [storeLoc, setStoreLoc] = useState('in');
    const [onlineFlow, setOnlineFlow] = useState('utm_source=instagram&utm_medium=default&utm_campaign=out');

    useEffect(() => {
        $('#in').prop('checked', true);
        $('#accounts1').prop('checked', true);
        $('#table_num').val('1');
        $('#instagram').prop('checked', true);
    }, [])
    
    
    const goLive = () => {
        if(storeId === '' || storeLoc === '') {
            toast('필수 항목들을 체크해주세요');
        } else {
            history.push(`/live/${storeLoc}/${storeId}?tblNum=${userData.dataset.tblNum}&${onlineFlow}`);
        }
    }

    const goLive2 = (path, prdtId='') => {
        if(prdtId === '') {
            history.push(`/${path}/${storeLoc}/${storeId}?tblNum=${userData.dataset.tblNum}&${onlineFlow}`);
        } else {
            history.push(`/${path}/${storeLoc}/${prdtId}/${storeId}?tblNum=${userData.dataset.tblNum}&${onlineFlow}`);
        }
    }

    const StoreIdClick = (e) => {
        setStoreId(e.target.value);
    }

    const StoreLocClick = (e) => {
        setStoreLoc($(e.target).val());
    }

    const TableNumClick = (e) => {
        console.log(e.target.value);
        if(e.target.value === '') {
            userData.setTableNum('1');
        } else {
            userData.setTableNum(e.target.value);
        }
    }

    const OnlineFlowClick = (e) => {
        switch (e.target.value) {
            case 'instagram':
                setOnlineFlow('utm_source=instagram&utm_medium=default&utm_campaign=out');
                break;
            case 'naverplace':
                setOnlineFlow('utm_source=naverplace&utm_medium=default&utm_campaign=out');
                break;
            case 'kakaomap':
                setOnlineFlow('utm_source=kakaomap&utm_medium=default&utm_campaign=out');
                break;
            case 'share':
                setOnlineFlow('utm_source=share&utm_medium=default&utm_campaign=out');
                break;
            default:
                break;
        }
    }


    return (
        <>  
            <div className="content" style={{ padding: '0px 15px' }}>
				<div className="page_title_wrap nofixed">
					<div className="inner">
						<h2>매장 선택</h2>
					</div>
				</div>
                <br />
                <ul className="account_sel_list">
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name"> 내부, 외부 메뉴판 선택 &#40;필수&#41;</span><br />
                        <input id="in" type="radio" name="storeloc" value='in' onClick={(e)=> StoreLocClick(e)}/>
                        <label htmlFor="in" className="usetap">
                            <span className="i_name"> 매장 안</span>
                        </label>
                        <input id="out" type="radio" name="storeloc" value='out' onClick={(e)=> StoreLocClick(e)} />
                        <label htmlFor="out" className="usetap">
                            <span className="i_name"> 매장 밖</span>
                        </label>
                    </li>
                    <br />
                    <br />
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name"> 매장 ID 선택 &#40;필수&#41;</span><br />
                        <input id="accounts1" type="radio" name="sel_storeid" value='220413USSTIN000739' onClick={(e)=> StoreIdClick(e)} />
                        <label htmlFor="accounts1" className="usetap">
                            <span className="i_name"> 220413USSTIN000739(사장님앱 연동)</span>
                        </label>
                        <input id="accounts2" type="radio" name="sel_storeid" value='210721USSTIN000033' onClick={(e)=> StoreIdClick(e)} />
                        <label htmlFor="accounts2" className="usetap">
                            <span className="i_name"> 210721USSTIN000033</span>
                        </label>
                        <br />
                        <input id="accounts3" type="radio" name="sel_storeid" />
                        <input id="accounts3" type="text" name="sel_storeid" placeholder='직접입력' onChange={(e)=> StoreIdClick(e)} />
                    </li>
                    <br />
                    <br />
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name"> 테이블 번호 입력 &#40;선택&#41;</span><br />
                        <input id="table_num" type="text" name="sel_accounts" onChange={(e)=>TableNumClick(e)} />
                        <label htmlFor="table_num" className="usetap">
                            <span className="i_name"> &#40;기본값 : 1&#41;</span>
                        </label>
                    </li>
                    <br />
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name"> utm_source 선택 &#40;선택&#41;</span><br />
                        <input id="instagram" type="radio" name="snsinflow" value='instagram' onClick={(e)=> OnlineFlowClick(e)} />
                        <label htmlFor="instagram" className="usetap">
                            <span className="i_name"> 인스타그램</span>
                        </label>
                        <input id="naverplace" type="radio" name="snsinflow" value='naverplace' onClick={(e)=> OnlineFlowClick(e)} />
                        <label htmlFor="naverplace" className="usetap">
                            <span className="i_name"> 네이버 지도</span>
                        </label>
                        <input id="kakaomap" type="radio" name="snsinflow" value='kakaomap' onClick={(e)=> OnlineFlowClick(e)} />
                        <label htmlFor="kakaomap" className="usetap">
                            <span className="i_name"> 카카오 지도</span>
                        </label>
                        <input id="share" type="radio" name="snsinflow" value='share' onClick={(e)=> OnlineFlowClick(e)} />
                        <label htmlFor="share" className="usetap">
                            <span className="i_name"> 씨식가 공유하기</span>
                        </label>
                    </li>
                    <br />
                    <br />
                    <li>
                        <span>다음 이동 Url:</span><br />
                        <span style={{ wordWrap: 'break-word' }}>{`/live/${storeLoc}/${storeId}?tblNum=${userData.dataset.tblNum}&${onlineFlow}`}</span>
                    </li>
                    <br />
                    <br />
                </ul>
                <ul>
                    <span className="i_name"> 다이렉트 Url 이동하기</span><br />
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name" onClick={()=>goLive2('live')}>라이브메뉴 이동</span>
                    </li>
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name" onClick={()=>goLive2('basket')}>장바구니 이동</span>
                    </li>
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name" onClick={()=>goLive2('menu')}>전체 메뉴판이동</span>
                    </li>
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <span className="i_name" onClick={()=>goLive2('detailmenu', '220325SSGPRDT000008')}>메뉴 상세이동</span>
                    </li>
                    <li style={{ listStyle: 'inside', listStyleType: 'dot' }}>
                        <a href="https://pf.kakao.com/_xjPxaWb" target="_blank" rel="noreferrer">카카오채널로 이동</a>
                    </li>
                </ul>
                <div className="btn_box fixed">
                    <div className="inner">
                        <button type="button" className="btn_lg btn_point usetap" onClick={()=>goLive()}>이동하기</button>
                    </div>
                </div>
			</div>
        </>
    )
});

export default Home;