import dayjs from 'dayjs';
import store from "../store";
import { toast } from '../libs/common';
const { userData } = store;

export const getOSType = () => {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) === null ? 'Android': 'iOS';
};

export const isIOS = () => {
    return getOSType() === 'iOS';
}

export const isAndroid = () => {
    return getOSType() !== 'iOS';
}

export function isNullData(data){
	if(data === "" || data === null || data === undefined || data === 'null' || data === 'undefined' ||(typeof data === 'object' && Object.keys(data).length === 0)) return true;
	return false;
}

export function isDataCheck(data){
	if(typeof(data) === 'undefined' || data === null || data === "" || data.length === 0) return false;
	return true;
}

export const isPC = () => {
    var pcDevice = "win16|win32|win64|mac|macintel";
    if ( navigator.platform ) {
        if ( pcDevice.indexOf(navigator.platform.toLowerCase()) < 0 ) {
            return false;
        } else {
            return true;
        }
    }
}

export const parsePathname = (pathStr) => {
    console.log("--------parsePathname------ pathStr : ", pathStr);
    if(pathStr.indexOf('=') < 0)
        return null;;
    let newPath = pathStr.replace(/^\?/,'');
    let newPathArray = newPath.split('&');
    let resultObj = {};
    for(let i = 0; i < newPathArray.length; i++ ) {
        let currData = newPathArray[i].split('=');
        resultObj[currData[0]] = currData[1];
    }
    return resultObj;
};


export const sortObjectList = (objectList, sortKey, sortType="ASC") => {
    const sortedList = objectList.sort(function (a, b) {
        let x = a[sortKey];
        let y = b[sortKey];
        if(sortType === "ASC") {
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
        } else {
            if (x < y) {
                return 1;
            }
            if (x > y) {
                return -1;
            }
        }
        return 0;
    });
    return sortedList;
}

export const sortHanFirstObjectList  =  (objectList, sortKey, sortType="ASC") => {

    /**
     * ㄱㄴㄷ : 한글
      #: 한글자음 > 영어 > 숫자 >기타(특수문자) 입니다.
      
     * @param {*} s
     * @returns 
     */
    if(sortType === "ASC"){
        objectList = sortObjectList(objectList, sortKey, sortType='ASC')
    }
    else{
        objectList = sortObjectList(objectList, sortKey, sortType='DESC')
    }   

      
    const addOrderPrefix = (s) => {
        var code = s.toLowerCase().charCodeAt(0);
        var prefix;
    
        // 한글 AC00—D7AF
        if (0xac00 <= code && code <= 0xd7af) prefix = 1000000;
        // 한글 자모 3130—318F
        else if (0x3130 <= code && code <= 0x318f) prefix = 2000000;
        // 영어 소문자 0061-007A
        else if (0x61 <= code && code <= 0x7a) prefix = 3000000;
        // 숫자
        else if (48 <= code && code <= 57) prefix = 4000000;
        // 그외
        else prefix = 9000000;
    
        return prefix + code;
    }

    const sortedList = objectList.sort(function (a, b) {
        let x = addOrderPrefix(a[sortKey]);
        let y = addOrderPrefix(b[sortKey]);

        if(sortType === "ASC") {
            if (x < y) {
                return -1;
            }
            if (x > y) {
                return 1;
            }
        } else {
            if (x < y) {
                return 1;
            }
            if (x > y) {
                return -1;
            }
        }
        return 0;
    });
  
    return sortedList;
}

/**
 * ㄱㄴㄷ : 한글
     #: 한글자음 > 영어 > 숫자 >기타(특수문자) 입니다.
    
* @param {*} s
* @returns 
*/
export const sortHanWordsList  =  (objectList, sortKey, sortType="ASC") => {
      
    const addOrderPrefix2 = (s, n) => {
        //var code = s.toLowerCase().charCodeAt(n);
        var code = s.charCodeAt(n);
        var prefix;
        
        // 한글 AC00—D7AF
        if (0xac00 <= code && code <= 0xd7af) prefix = 1000000;
        // 한글 자모 3130—318F
        else if (0x3130 <= code && code <= 0x318f) prefix = 2000000;
        // 영어 소문자 0061-007A
        else if (0x61 <= code && code <= 0x7a) prefix = 3000000;
        // 숫자
        else if (48 <= code && code <= 57) prefix = 4000000;
        // 그외
        else prefix = 9000000;
        
        return prefix + code;
    
    }

    const sortedList = objectList.sort(function (_a, _b) {
        let changeFlag = false;
        let firstvalue = -2;
        let len = 0;
        let tmpCompareValue = 0;
        let tmpA = '';
        let tmpB = '';
        let targetA = _a[sortKey];
        let targetB = _b[sortKey];

        //console.log('sortHanWordsList [A:' + targetA + ']', '[B:' + targetB + ']');

        if ( tmpCompareValue === 0 ){
           
            do {

                tmpA = addOrderPrefix2(targetA, len);
                tmpB = addOrderPrefix2(targetB, len);

                //console.log('sortHanWordsList _a=> ' + len + ']', tmpA);
                //console.log('sortHanWordsList _b=> ' + len + ']', tmpB);

                //--------------------------------------------------------
                //compare
                //--------------------------------------------------------
                if ( tmpA > tmpB ){
                    tmpCompareValue = 1;
                } else if ( tmpB > tmpA ) {
                    tmpCompareValue = -1;
                } else { 
                    tmpCompareValue = 0;
                }

                if ( len === 0 ) firstvalue = tmpCompareValue;

                if ( firstvalue !== tmpCompareValue ) {
                    changeFlag = true;

                    if ( firstvalue !== 0 ){
                        tmpCompareValue = firstvalue;
                    }
                    //console.log('==>compare break =>', tmpCompareValue);

                    break;
                }

                //--------------------------------------------------------
                //길이체크
                //--------------------------------------------------------
                /*
                if ( _a.length <= ( len - 1 ) && _a.length < _b.length ){
                    tmpCompareValue = 1;
                }else if ( _b.length <= ( len - 1 )  && _a.length < _b.length ){
                    tmpCompareValue = -1;
                } else if ( _a.length === _b.length && _a.length === ( len - 1 )  ){
                    tmpCompareValue = 0;
                }

                firstvalue = tmpCompareValue;
                

                if ( firstvalue !== tmpCompareValue ){
                    changeFlag = true;

                    console.log('==>compare break');
                    break;
                }
                */
                if ( targetA.length <= ( len + 1 ) || targetB.length <= ( len + 1 ) || targetA.length === 1 || targetB.length === 1 ) {
                    //console.log('==> chceck length :', tmpCompareValue);
                    if ( tmpCompareValue === 0 ) {
                        if ( targetA.length > targetB.length ){
                            tmpCompareValue = 1;
                        }else if ( targetA.length < targetB.length ){
                            tmpCompareValue = -1;
                        }
                    }
                    changeFlag = true;
                    break;
                }

                len++;

                //console.log('==> roof  :', changeFlag);

            } while ( !changeFlag );
        }
        

        if ( sortType === 'DESC' )  tmpCompareValue = tmpCompareValue * -1;

        //console.log('==> break end :', tmpCompareValue);

        return tmpCompareValue;
        
    });
  
    return sortedList;
}

export const addHyphenPhoneNo = (phoneNum) => {
    return phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
}

/* 매장명 한글,알파벳,숫자,공백 */
export const checkStoreName = (storeNm) => {
    const reg_hanengnum = /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|\s]+$/;
    if (!reg_hanengnum.test(storeNm)) {
        return false;
    }
    return true;
}
export const checkStoreDetailNumber = (numberVal) => {
    const reg_num = /^[0-9|,]+$/;
    if (!reg_num.test(numberVal)) {
        return false;
    }
    return true;
}


/**
 * 한글, 영문, 숫자만 가능
 * @param {*} _text 
 * @returns 
 */
export const checkIMEEnabledType = (_text) => {
    const reg_hanengnum = /^[ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|\s]+$/;

    if ( _text === ''){
        return true;
    }else if (!reg_hanengnum.test(_text)) {
        return false;
    }
    return true;
}

/* true : AM / false : PM , dateStr형태 : yyyy-mm-dd HH:mi:ss*/
export const getAMPM = (dateStr) => {
    var hour = dateStr.substr(11,2);
    if (parseInt(hour) > 11){
        return false;
    }
    return true;
}

export const getTimes = (dateStr) => {
    if ( dateStr.length > 18){
        return dateStr.substr(11,8);
    }else{
        return dateStr;
    }
    
}

export const getDate = (dateStr) => {
    if ( dateStr.length > 10){
        return dateStr.substr(0,10);
    }else{
        return dateStr;
    }
}

const defaultFormatYMD = 'YYYY-MM-DD';
// const defaultFormatYMDHIS = 'YYYY-MM-DD hh:mm:ss';

const PRETTY_DATETYPE_MSGLOG = "MSGLOG";
const PRETTY_DATETYPE_MSGDETAILLOG = "MSGDETAILLOG";
/**
 * 메시지 수발신용 날짜표기
 * @param {*} _srcDate 
 * @param {*} _srcFormat 
 * @returns 
 */
export const prettyMsgLog = ( _srcDate, _srcFormat ) => {
    return getPrettyDate(PRETTY_DATETYPE_MSGLOG, _srcDate, _srcFormat)
}

export const prettyMsgDetailLog = ( _srcDate, _srcFormat ) => {
    return getPrettyDate(PRETTY_DATETYPE_MSGLOG, _srcDate, _srcFormat)
}

/**
 * 서비스내 날짜 표기법 정의
 * @param {*} _type 
 * @param {*} _srcDate 
 * @param {*} _srcFormat 
 */
export const getPrettyDate = (_type, _srcDate, _srcFormat) => {
    let srcData = dayjs(_srcDate, _srcFormat);
    //console.log("222", srcData);
    if ( _type === PRETTY_DATETYPE_MSGLOG) {
        //if ( srcData.isSame(new Date(), "day")){
        if ( dayjs().diff(srcData) > 0 ){
            return srcData.format('LT');
        }else{
            return srcData.format("YYYY-MM-DD HH:mm:ss");
        }
    } else if ( _type === PRETTY_DATETYPE_MSGDETAILLOG){
        return srcData.format('LT');
    } else {
        return dayjs(_srcDate, _srcFormat);
    }
}

export const getDateMont = (_date) => {
    return getDateMontAllType(defaultFormatYMD, _date, defaultFormatYMD);
}

export const getDateMontTwoWay = (_date, _format) => {
    return getDateMontAllType(defaultFormatYMD, _date, _format);
}

export const getDateMontAllType = (_srcFormat, _date, _destFormat) => {

    if ( _destFormat === null ){
        _destFormat = defaultFormatYMD;
    }

    if ( _srcFormat === null || _srcFormat === '' ){
        _srcFormat = defaultFormatYMD
    }

    if ( _date === null ){
        return dayjs().format(_destFormat);
    }else{
        return dayjs(_date, _srcFormat).format(_destFormat);
    }
}

export const getPrevMonth = () => {
    var date = new Date();
    var year = date.getFullYear().toString();
    var month = date.getMonth();
    
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + "-" + month + "-" + day + " " + hour + ":" + minites + ":" + seconds;
}

export const getCurrentDate = () => {
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + month + day + hour + minites + seconds + "00";
}

export const getSimpleDate = () => {
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    return year + month + day;
}

export const getCurrentDateTimeString = () =>
{
    var date = new Date();
    var year = date.getFullYear().toString();

    var month = date.getMonth() + 1;
    month = month < 10 ? '0' + month.toString() : month.toString();

    var day = date.getDate();
    day = day < 10 ? '0' + day.toString() : day.toString();

    var hour = date.getHours();
    hour = hour < 10 ? '0' + hour.toString() : hour.toString();

    var minites = date.getMinutes();
    minites = minites < 10 ? '0' + minites.toString() : minites.toString();

    var seconds = date.getSeconds();
    seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString();

    return year + month + day + hour + minites + seconds;
}

export const comma = (str) => {
    str = String(str);
    return str.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
}

export const uncomma = (str) => {
    str = String(str);
    return str.replace(/[^\d]+/g, '');
}

export const getMaskName = (nameStr) => {
    return nameStr.replace(/^(.)./, '$1*');
}

export const addHyphenMaskPhoneNo = (phoneNum) => {
    const phoneNoString = phoneNum.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1-$2-$3");
    const phoneNoArray = phoneNoString.split("-");
    return phoneNoArray[0] + "-" + phoneNoArray[1].substr(0,2) + "**-" + phoneNoArray[2].substr(0,2) + "**";
}

export const getByteLength = (targetStr)  => {

    if ( targetStr !== undefined && targetStr !== null && targetStr !== '') targetStr = targetStr.trim();
    else targetStr = '';

    var intByteLength   = 0;
    var chrData         = null;

    for(var i=0; i< targetStr.length; i++) {

        chrData = escape(targetStr.charAt(i));
        if(chrData.length === 1) {
            intByteLength ++;
        } else if (chrData.indexOf("%u") !== -1) {
            intByteLength += 2;
        } else if (chrData.indexOf("%") !== -1) {
            intByteLength += chrData.length/3;
        }
    }

    return intByteLength;
}

//3자리 마다 , 넣기 우리나라 원표시
export const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const PairingMenuBasketPush = async (menu) => {
    let basketList = [];
    let indexSave = [];
    basketList = userData.dataset.basketList; // 기존에 담겨있는거 가져옴
    
    const sameIdList2 = basketList.filter((items,idx) => {
        if(items.prdtId === menu.prdtId && items.options.length === 0){
            indexSave.push(idx); //수정할 원소의 index 체크
            return items;
        }
    })

    if(sameIdList2.length > 0) {
        //같은 메뉴가 여러개 일 경우 하나하나 다 돌면서 옵션 체크
        for(let i = 0; i < sameIdList2.length; i++) {
            const optionsArray = sameIdList2[i].options // 메뉴의 옵션을 담음
            //단일 옵션
            if(optionsArray.length < 1) {
                if(sameIdList2[i].prdtNm === menu.prdtNm && sameIdList2[i].price === menu.price ){
                    let count2 = indexSave[i];
                    userData.dataset.basketList[count2].prdtCnt = userData.dataset.basketList[count2].prdtCnt + 1
                    userData.dataset.basketList[count2].totalPrice = userData.dataset.basketList[count2].prdtCnt * userData.dataset.basketList[count2].price
                    toast("장바구니에 메뉴의 수량을 변경했습니다.");
                    return;
                }
            }
        }
    }
    
    //위의 경우가 아니라면 그냥 추가해주면 됨
    basketList.push(menu);
    userData.setBasketPut(basketList);
    toast("장바구니에 메뉴를 추가했습니다.");
}


/**
 * 참고 menu.js 
 * @param {*} menu baskeyPut함수에 있는 menu 객체
 * @param {*} count 각 페이지에서 사용하는 음식 수량 state
 * @returns 
 */
export const menuBasketPush = async (menu, count) => {
    let basketList = [];
    let indexSave = [];

    basketList = userData.dataset.basketList; // 기존에 담겨있는거 가져옴
    
    //옵션이 있는것과 없는것을 나눔 
    if(menu.options.length > 0) {
        /*******  옵션이 있을 경우엔 아래 로직 수행 ********/
            
        
        // 장바구니에 넣을 상품(menu)과 기존 store에(basketList)있는 상품의 id를 비교해 같은 것을 모두 가져옴 
        const sameIdList = basketList.filter((items,idx) => {
            if(items.prdtId === menu.prdtId){
                indexSave.push(idx); //수정할 원소의 index 체크 -> 나중에 해당 원소만 수정하기 위함
                return items;
            }
        })

        //만약 같은 id의 상품이 1개라도 존재한다면 아래 로직 수행
        if(sameIdList.length > 0) {
            for(let i = 0; i < sameIdList.length; i++){
                const optionsArray = sameIdList[i].options // 메뉴의 옵션을 담음

                // 기존 store 상품의 옵션의 length와 현재 담으려고 하는 상품의 옵션의 length가 같을때만 로직 진행
                //(옵션의 길이가 다른 경우 같은 상품을 주문하지 않는 것으로 판단)
                if(optionsArray.length === menu.options.length){
                    //각 메뉴의 옵션의 키값이 같을경우에만 가능한 로직
                    let menuOptionsStr = JSON.stringify(menu.options); // menu의 옵션 객체를 스트링으로 변환
                    // 변환된 옵션의 스트링 값 store의 옵션 값을 스트링으로 변환해 같은게 있는지 비교 
                    let same = optionsArray.filter(item => {
                        return menuOptionsStr.includes(JSON.stringify(item));
                    });
                    
                    //같은게 있을 경우 해당 로직 진행 
                    //TODO : same.length === optionsArray.length 하는 이유 굳이 없어도 될 것 같음
                    if(same.length === optionsArray.length){
                        let count2 = indexSave[i];
                        userData.dataset.basketList[count2].prdtCnt = userData.dataset.basketList[count2].prdtCnt + count
                        userData.dataset.basketList[count2].totalPrice = userData.dataset.basketList[count2].prdtCnt * userData.dataset.basketList[count2].price
                        toast("장바구니에 메뉴를 추가했습니다.");
                        return;
                    }
                }
            }
        }
    } else {
        const sameIdList2 = basketList.filter((items,idx) => {
            if(items.prdtId === menu.prdtId && items.options.length === 0){
                indexSave.push(idx); //수정할 원소의 index 체크
                return items;
            }
        })

        if(sameIdList2.length > 0){
                //같은 메뉴가 여러개 일 경우 하나하나 다 돌면서 옵션 체크
                for(let i = 0; i < sameIdList2.length; i++){
                    const optionsArray = sameIdList2[i].options // 메뉴의 옵션을 담음
                    //단일 옵션
                    if(optionsArray.length < 1){
                        if(sameIdList2[i].prdtNm === menu.prdtNm  && 
                            sameIdList2[i].price === menu.price  
                            // sameIdList2[i].prdtDesc === menu.prdtDesc 
                            ){
                                let count2 = indexSave[i];
                                userData.dataset.basketList[count2].prdtCnt = userData.dataset.basketList[count2].prdtCnt + count
                                userData.dataset.basketList[count2].totalPrice = userData.dataset.basketList[count2].prdtCnt * userData.dataset.basketList[count2].price
                                toast("장바구니에 메뉴를 추가했습니다.");
                                return;
                        }
                    }
                }
        }
    }
    
    //위의 경우가 아니라면 그냥 추가해주면 됨
    toast("장바구니에 메뉴를 추가했습니다.");
    basketList.push(menu);
    userData.setBasketPut(basketList);
}

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const Base64 = {
  btoa: (input = '')  => {
    let str = input;
    let output = '';

    for (let block = 0, charCode, i = 0, map = chars;
    str.charAt(i | 0) || (map = '=', i % 1);
    output += map.charAt(63 & block >> 8 - i % 1 * 8)) {

      charCode = str.charCodeAt(i += 3/4);

      if (charCode > 0xFF) {
        throw new Error("'btoa' failed: The string to be encoded contains characters outside of the Latin1 range.");
      }

      block = block << 8 | charCode;
    }

    return output;
  },

  atob: (input = '') => {
    let str = input.replace(/=+$/, '');
    let output = '';

    if (str.length % 4 == 1) {
      throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
    }
    for (let bc = 0, bs = 0, buffer, i = 0;
      buffer = str.charAt(i++);

      ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
        bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
    ) {
      buffer = chars.indexOf(buffer);
    }

    return output;
  }
};