import React from 'react'
import { observer } from "mobx-react";

const NetworkErr = observer(({ history }) => {

    const goBackClick = async() => {
        history.goBack();
    }

    return (
        <div id="wrap" className="fixed_bottom">
            <div className="err_page">
                <div className="page_inner">
                    <span className="err_img network"></span>
                    <h1>페이지 접근 오류</h1>
                    <p>
                        허용되지 않는 경로로 페이지에 접근하셨습니다.<br/>
                        다시 시도해주세요.
                    </p>
                    <div className="btn_box fixed">
                        <div className="inner">
                            <button type="button" className="btn_lg btn_point usetap" onClick={() => goBackClick()}>다시 시도</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default NetworkErr;