import React, { useEffect, Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import store from './store';
import $ from 'jquery';
import * as NativeBridge from './utils/NativeBridge';
import * as common from './common/common';
import SohoApi from './api/SohoApi';
import Home from './components/home/Home';
import Live from './components/home/Live';
import Menu from './components/menu/Menu';
import Basket from './components/cart/Basket';
import DetailMenu from './components/menu/detailmenu/DetailMenu';
import * as CONSTS from './const/index'
import NetworkErr from './components/networkerror/NetworkErr';

const Main = observer(({ match, location, history }) => {

    const { userData, userInfo } = store;
    
    useEffect(() => {
        
        // fallowHistory();
        setComIdFromUri();

       //native event set
        const subscription = NativeBridge.eventObservable.subscribe(event => {
            
            console.log("----------------- from Native Event : Main.js ------------------");
            console.log(event);
            switch (event.type) {                    

                case 'CALL_BACKPRESS':
                    console.log("Go Back!");
                    history.goBack();
                break;

            default:
                break;
            }

        });

        //------------------------------------ PC에서 backkey test 용 (ESC)
        if(common.isPC()) {            
            $(document).on("keydown", (event) => {
                if(event.key === "Escape") {
                    window.NativeInvoker({type:"CALL_BACKPRESS"});
                    event.preventDefault();
                }
            });
        }
        //------------------------------------ PC에서 backkey test 용 (ESC)


        $('html, body').scrollTop(0);

        return () => {
          subscription.unsubscribe();
        };
    }, []);

    useEffect(() => {
        console.log(location.pathname);
        console.log(location.pathname.indexOf('guide'));
        const splitPathname = location.pathname.split('/');
        const storeId = splitPathname[splitPathname.length - 1]
        async function fetchStoreInfo() {
            if(location.pathname.indexOf('guide') === -1 && location.pathname.indexOf('networkerror') === -1 && 
            location.pathname.indexOf('basket') === -1 && location.pathname.indexOf('detailmenu') === -1) {
                await getStoreInfo(storeId);
            }
        }
        fetchStoreInfo()
    }, [location.pathname])
    
    useEffect(() => {
        fallowHistory();
        console.log('location', location);
    },[location.search])

    const getStoreInfo = async(storeId) => {
        try {
            await SohoApi.getStoreInfo({
                storeId: storeId
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS) {
                    if(!common.isNullData(rst.data)) {
                        userInfo.setStoreInfo(rst.data);
                        userData.setStoreId(rst.data.storeId);
                        console.log(userInfo.dataset.storeInfo);
                    } else {
                        history.replace(`/networkerror`);
                    }
                } else {
                    history.replace(`/networkerror`);
                }
            }).catch((e) => {
                console.log(e);
                history.replace(`/networkerror`);
            })
        } catch(e) {
            console.log(e);
            history.replace(`/networkerror`);
        }
    }

    //TODO : histType 추가 확인, 최초에 한번만, 유입경로로 안올경우 
    const fallowHistory = async () => {
        
        const pathInfo = common.parsePathname(location.search);
        let flowType = "";
        let flowPath = "";
        let histType = ""; // pathInfo 객체에서  찾아서 넣으면 됨

        console.log("pathInfo",pathInfo);
        console.log("match.params",match.params);
        //utm_source가 있을경우
        if(!common.isNullData(pathInfo?.utm_source)){
            const searchPath = pathInfo.utm_source
            const splitPathname = location.pathname.split('/');
            const storeId = splitPathname[splitPathname.length - 1]
            console.log("searchPath", searchPath);
                switch(searchPath){
                    case "naverplace" :
                        flowType = "N"
                        flowPath = "?utm_source=naverplace&utm_medium=default&utm_campaign=out"
                    break;
                    case  "kakaomap" :
                        flowType = "K"
                        flowPath = "?utm_source=kakaomap&utm_medium=default&utm_campaign=out"
                    break;
                    case "instagram" :
                        flowType = "I"
                        flowPath = "?utm_source=instagram&utm_medium=default&utm_campaign=out"
                    break;
                    case "share" :
                        flowType = "S"
                        flowPath = "?utm_source=share&utm_medium=default&utm_campaign=out"
                    break;
                default :
                    flowType = "E"
                    flowPath =''
                    break;
                }

            await SohoApi.getAddInflowHistory({
                "storeId": storeId,
                "histType": 'O', //이력타입 (O: 온라인, S: 공유하기, L: 좋아요)
                "flowType": flowType, //histType O일 경우 - 온라인 종류 (N - 네이버, K - 카카오, I - 인스타, F - 페이스북, E - 기타)
                "flowPath": flowPath //flowType E일 경우 - 유입경로
            }).then((rst) => {
                if(rst.retCode === CONSTS.SOHOAPI_RETCODE_SUCCESS){
                    console.log("성공");
                }
            }).catch((e) => {
                console.log(e);
            })
        }
    }

    const setComIdFromUri = () => {
        console.log(location);
        
        console.log("test",common.parsePathname("?utm_source=instagram&utm_medium=default&utm_campaign=out"))
        const pathInfo = common.parsePathname(location.search);
        console.log('pathInfo : ', pathInfo);

        if(pathInfo !== null) {
            userData.setParamObj(pathInfo);
            // userData.setComId(pathInfo.comid);
            // userData.setStoreId(pathInfo); // 스토어 아이디
        }
    }

    return (
        <> 
            <Suspense fallback={<div></div>}>
                <div id="wrap" className='fixed_bottom'>
                    <div id="container">
                        <Switch> 
                            <Route path={`/guide`} component={Home} />
                            <Route path={`/networkerror`} component={NetworkErr} />
                            <Route path={`/live/:storeloc/:storeId`} component={Live} />
                            <Route path={`/basket/:storeloc/:storeId`} component={Basket} />
                            <Route path={`/menu/:storeloc/:storeId`} component={Menu} />
                            <Route path={`/detailmenu/:storeloc/:prdtId/:storeId`} component={DetailMenu} />                          
                            <Redirect to={`/networkerror`} component={NetworkErr} />
                        </Switch>
                    </div>
                </div>
                <div id="toast"></div>               
            </Suspense>
        </>
    )
    
});
export default Main;