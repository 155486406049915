/* server api url */

export const version = "0.0.1";
// export const sohoApiUrl = "http://localhost:3000";
// export const centrexApiUrl = "http://localhost:3000";
// export const cctvApiUrl = "http://localhost:3000";

export const sohoApiUrl_PRD = "";
export const sohoApiUrl_DEV = "";
export const sohoApiUrl_LOC = "";

export const centrexApiUrl_PRD = "";
export const centrexApiUrl_DEV = "";
export const centrexApiUrl_LOC = "";

export const cctvApiUrl_PRD = "";
export const cctvApiUrl_DEV = "";
export const cctvApiUrl_LOC = "";

export const weatherApiUrl = "";
export const noticeApiUrl = "";

export const SOHOAPI_RETCODE_SUCCESS = "0000";
export const SOHOAPI_AUTH_LOGOUT_ERROR = [
    {code: "9007", title: "알림", msg: "인증에 오류가 발생했습니다."},
    {code: "9003", title: "알림", msg: "인증에 실패하였습니다."},
    {code: "1501", title: "알림", msg: "탈퇴한 사용자 입니다."},
    {code: "1708", title: "알림", msg: "패밀리 사용자가 아닙니다."}
];