import {webUI} from '../libs/common';

export const tabUi = (function() {
    return {
		"initSubTab": function(container) {
			let subtab = container;
			//tabUi.activeBar(0,subtab);
			webUI.addDelegate(subtab, "click", ".tab_item", function(e) {
				e.preventDefault();
				if (!this.classList.contains('active')) {
					let indexNum = webUI.getChildIndex(this);
					subtab.querySelector('.active').classList.remove('active');
					this.classList.add('active');
					//tabUi.activeBar(indexNum,subtab);
					webUI.animatedScrollTo(subtab, (subtab.querySelectorAll('li')[indexNum].offsetLeft + subtab.querySelectorAll('li')[indexNum].clientWidth * 0.5) - (subtab.clientWidth * 0.5), 300);
				}
			});
			window.addEventListener('resize', function(e) {
				let indexNum = webUI.getChildIndex(subtab.querySelector('.active'));
				//tabUi.activeBar(indexNum,subtab);					
			});
        },		
    }
})();