import { Subject, throwError } from 'rxjs';
import { isIOS, isPC, getCurrentDateTimeString } from '../common/common';
import * as SessionStore from '../store/SessionStore';
import * as LocalStore from '../store/LocalStore';

const kmcUrl                = process.env.REACT_APP_KMC_URL;
const kmcCallbackUrl        = process.env.REACT_APP_KMC_CALLBACK_URL;
const zipSearchUrl          = process.env.REACT_APP_ZIPCODE_URL;
const zipSearchCallbackUrl  = process.env.REACT_APP_ZIPCODE_CALLBACK_URL;
const zipSearchAuthKey      = process.env.REACT_APP_ZIPCODE_AUTHKEY;
const zipSearchReturnType   = process.env.REACT_APP_ZIPCODE_RETURN_TYPE;

/* from native */
export const eventObservable = new Subject();
export function NativeInvoker(eventData) {
  // if(typeof(eventData) === "string") return;
  // const event = JSON.parse(eventData);
  const event = eventData;
  // if(typeof(eventData) === "string") {
  //   event = JSON.parse(eventData);
  // }
  // const event = JSON.parse(eventData);
  console.debug('onEvent(NativeInvoker)', event);
  eventObservable.next(event);
};
window.NativeInvoker = NativeInvoker;

// -- native Event TEST
/*
window.NativeInvoker(
  {
    "type" : "CALL_DEVICE_EVENT", 
    "param" : 
    {
      "action": "callbackmc",
      "detail" : {
        "userNm" : "홍길동",
        "userCi" : "asdf8sd7f897d9f8", 
        "userCtn" : "01012345678", 
        "userBday" : "19801201", 
        "pushRegi" : "1", 
        "sex" : "M"      
      }
    }
  }
);
*/





/* to native */
const iosProxy = (event) => {
  let rtn = "{}";
  try {
    // console.log(event);
    // alert(event)
    rtn = window.webkit.messageHandlers.sendEvent.postMessage(event);
  } catch(e) {
    rtn = "{\"type\":\"NO I/F\"}";
  }
  return rtn;  
}

class IosApis {
  constructor() {
    this.sendEvent = (event) => { return iosProxy(event); };
  }
}
let iOSInterface;
if (!isPC() && isIOS()) {
  iOSInterface = new IosApis();
}

class DummyInterface {
  constructor() {
    this.sendEvent = (event) => { return "{\"type\":\"NO I/F\"}"; };
  }
}
let noInterface = new DummyInterface();

const getNativeInterface = () => {
  if(!isPC()) {
    if (window.SohoInterface) {
      return window.SohoInterface;
    } else if (iOSInterface) {
      return iOSInterface;
    } else {
      return noInterface;
    }
  } else {
    return pcInterface;
  }
  return null;
}

export const sendEvent = (event) => {
  try {
    const nativeInterface = getNativeInterface();
    if (nativeInterface) {
      nativeInterface.sendEvent(JSON.stringify(event));
    }
    return true;
  } catch (ex) {
    return false;
  } finally {
    setNativeEventLog(event);
  }
}

export const getDeviceInfo = () => {
  const evt = { 
    "type" : "GET_DEVICE_INFO", 
    "param": {
        "info_type":"dev_model, os, ctn, network, ip_addr, mac, tel_info, iccid, sim_operation, serial_no, imei, carrier_type, version"
    }
  };
  return sendEvent(evt);
}

export const shutdownApp = () => {
  const evt = { 
    "type" : "CALL_SHUTDOWN_APP"
  };
  return sendEvent(evt);
};


//==================================================================
// 로딩관련
//------------------------------------------------------------------

//로딩 스타트
export const getLoadingStart = () => {
  const evt = {
    "type" : "LOADING_START",
    "param" : {
      "type" : "I",
      "message" : ""
    }

  };
  return sendEvent(evt);
}

export const getLoadingStartMsg = (message="") => {
  const evt = {
    "type" : "LOADING_START",
    "param" : {
      "type" : "T",
      "message" : message
    }

  };
  return sendEvent(evt);
}

//로딩 완료
export const getLodingEnd = () => {
  const evt = {
    "type" : "LOADING_END"
  };
  
  return sendEvent(evt);
};


export const sendUserActionLog = (actionType="", actionDetail1="", msg = "", actionDetail2 = "") => {
  const loadingTime = getCurrentDateTimeString();
  let bdpLoadDttm = '';
  if (actionType === 'gnb' && msg === 'end') {
    bdpLoadDttm = getCurrentDateTimeString();
  } else if (actionType === 'message') {
    bdpLoadDttm = msg;
  }
  const evt = {
    "type" : "USER_ACTION_LOG",
    "param" : {
      "USER_TYPE" : "J" ,
      "USER_ID" : '',
      "STORE_ID" : '', 
      "FAMILY_YN" : '', 
      "ACTION_TYPE" : actionType,
      "ACTION_DETAIL1" : actionDetail1,
      "ACTION_DETAIL2" : actionDetail2,
      "BDP_LOAD_DTTM" : bdpLoadDttm,
      "TOKEN" : ''
      ,"RSP_TIME" : ""
      ,"VIEW_CURR" : ""
      ,"VIEW_CURR_DTL" : ""
      ,"ACTION_DETAIL3" : ""
      ,"ACTION_DETAIL4" : ""
      ,"ACTION_DETAIL5" : ""
      ,"ACTION_DETAIL6" : ""
      ,"ACTION_DETAIL7" : ""
      ,"R1" : ""
      ,"R2" : ""
      ,"R3" : ""
      ,"R4" : ""
      ,"R5" : ""
    }
  };
  return sendEvent(evt);
}

//------------------------------------------------------------------

const setNativeEventLog = (evt) => {
  console.log('------------------------ setNativeEventLog ----------------------');
  console.log("setNativeEventLog: " , evt);
};


//------------------------------------------------------------------- pc test 용
const pcInterface = {
  sendEvent: function(param) {
    const Data = JSON.parse(param);
  
    console.log("========== Native Interface Data :: pc Emul Interface ==============", Data);
    console.log(Data);

    if(Data.type === "GET_DEVICE_INFO") {
      const ret = {
        "type" : Data.type, 
        "param": {
            app_name: "U+SOHO500TF"
            ,carrier_type: "L"
            ,ctn: "01022332233"
            ,ctn_info: "01022332233"
            ,dev_model: "PC"
            ,iccid: ""
            ,imei: ""
            ,ip_addr: "0.0.0.0"
            ,mac_info: ""
            ,network: "WIFI"
            ,os: "11"
            ,os_type: "android"
            ,push_key: "abcd"
            ,serial_no: "unknown"
            ,sim_operator: "45005"
            ,version: "0.5.0"
        }        
      }
      console.log("res : " , ret);
      window.NativeInvoker(ret);

      return JSON.stringify(ret);
    }


    if(Data.type === "LOADING_START" || Data.type === "LOADING_END") {
      const ret = {
        "type": Data.type         
      }
      console.log("res : " , ret);
      return JSON.stringify(ret);
    }

    
  }
}

